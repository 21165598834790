import React, {useEffect, useState} from 'react'

import './feedback.scss';
import {usePostFeedbackMutation} from "../../../hooks/slices/feedbackSlice";
import {useAppDispatch} from "../../../app/hooks";
import {showCustomErrorPopup, showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {useTranslation} from "react-i18next";
import {EmailUtil} from "../../../utils/email";
import {SaveButton} from "../../../components/saveButton/SaveButton";
import {useAnimation, motion, AnimationControls} from "framer-motion";
import {FramerMotionUtil} from "../../../utils/framermotion";


export const Feedback: React.FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [postFeedback, {isSuccess, isLoading, isError, error}] = usePostFeedbackMutation();
    const controls: AnimationControls = useAnimation();
    const [sentFeedback, setSentFeedback] = useState(false);
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [message, setMessage] = useState("");
    const inputsAreValid = (): boolean => {
        if (name.trim() === "") return false;
        if (company.trim() === "") return false;
        if (emailAddress.trim() === "") return false;
        if (message.trim() === "") return false;
        return true;
    }
    const sendFeedback = ():boolean => {
        if (!inputsAreValid()) {
            dispatch(showCustomErrorPopup(t("intake.fill_in_all_fields")));
            FramerMotionUtil.shakeAnimation(controls);
            return false;
        }
        else if (!EmailUtil.emailAddressIsValid(emailAddress)) {
            dispatch(showCustomErrorPopup(t("settings.invalid_email_address")));
            FramerMotionUtil.shakeAnimation(controls);
            return false;
        }
        else {
            postFeedback({name: name, emailAddress: emailAddress, company: company, message: message})
            setSentFeedback(true);
            return true;
        }
    }

    useEffect(() => {
        if(isSuccess){
            setName("");
            setMessage("");
            setCompany("");
            setEmailAddress("");
            let isMounted = true; //needed to avoid update on unmounted component error
            setTimeout(() => {
                setSentFeedback(false);
            }, 2000);
            return () => { isMounted = false };
        } else if (isError && error) dispatch(showErrorPopup(error));
    }, [isSuccess, isError, error]);

    return (
        <div>
            <div className='feedback-section-alignment'>
                <div className='page-alignment'>
                    <div>
                        <h1>Feedback</h1>
                    </div>
                </div>
                <div className='feedback-dec-alignment'>
                    <h2>{t("settings.feedback_your_input_is_invaluable")}</h2>
                    <p>{t("settings.feedback_we_are_constantly_evolving")}</p>
                    <span>{t("settings.feedback_fill_out_the_form")}</span>
                    <h2>{t("settings.feedback_thank_you")}</h2>
                </div>
                <motion.div className='input-box' animate={controls}>
                    <div className='input'>
                        <input type="text" maxLength={255}  placeholder={t("settings.feedback_name_placeholder")} value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className='input'>
                        <input type="text" maxLength={255}  placeholder={t("settings.feedback_mail_placeholder")} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)}/>
                    </div>
                    <div className='input'>
                        <input type="text" maxLength={255}  placeholder={t("settings.feedback_company_placeholder")} value={company} onChange={(e) => setCompany(e.target.value)}/>
                    </div>
                </motion.div>
                <motion.div className='feedback-type-message' animate={controls}>
                    <div className='input'>
                        <textarea placeholder={t("settings.feedback_message_placeholder")} value={message} maxLength={2500} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                </motion.div>
                <SaveButton saving={sentFeedback} save={sendFeedback}
                            customSaveMessage={t("settings.send_feedback")}
                            customSavingMessage={t("settings.sending_feedback")}
                            customSavedMessage={t("settings.sent_feedback")}
                />
            </div>
        </div>
    )
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ArrowUpIcon, StarIcon, TrashIcon } from "../../../components/icons";

export interface EmailCardProps {
  email: string;
  isPrimary: boolean;
  removeAdditionalAddressee?: (email: string) => void;
  makePrimary?: (email: string) => void;
}

export const EmailCard: React.FC<EmailCardProps> = ({ email, isPrimary, removeAdditionalAddressee, makePrimary }) => {
  const { t } = useTranslation();
  const [showButtons, setShowButtons] = useState(false);
  return (
    <motion.div
      className="email-card"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
      whileHover={{ scale: 1.01 }}
    >
      <div className="input">
        <span>{email}</span>
      </div>
      {!isPrimary && showButtons && makePrimary && removeAdditionalAddressee && (
        <div className={"edit-buttons"}>
          <div onClick={() => makePrimary(email)} title={t("settings.make_primary")}>
            <ArrowUpIcon />
          </div>
          <div onClick={() => removeAdditionalAddressee(email)} title={t("settings.delete")}>
            <TrashIcon />
          </div>
        </div>
      )}
      {isPrimary && showButtons && (
        <div className={"edit-buttons"} title={t("settings.primary")}>
          <StarIcon />
        </div>
      )}
    </motion.div>
  );
};

import { ResourceKey } from "i18next";

export const Settings: ResourceKey = {
	settings: "Configuration",
	account: "Compte",
	password: "Mot de passe",
	company: "Entreprise",
	invoice: "Facturation",
	labels: "Étiquettes",
	email: "E-mail",
	profiles: "Profils",
	countries: "Pays",
	no_countries_left_message_start: "Votre plan ne permet que",
	no_countries_left_message_end_singular: "pays. Pour modifier votre plan, allez dans l'onglet 'Plan tarifaire'.",
	no_countries_left_message_end_plural: "pays. Pour modifier votre plan, allez dans l'onglet 'Plan tarifaire'.",
	plan: "Plan tarifaire",
	feedback: "Commentaires",
	save: "Sauvegarder",
	saving: "Sauvegarder...",
	saved: "Sauvegardé",
	totalPrice: "Prix total",
	street: "Rue",
	number: "Numéro",
	zip: "Code postale",
	town: "Municpalité",
	phone: "Téléphone",
	current_password: "Mot de passe actuel",
	new_password: "Mot de passe nouveau",
	confirm_new_password: "Confirmer le mot de passe noveau",
	add_or_remove_from_account: "Ajouter ou supprimer des adresses e-mail de votre compte",
	manage_email_addresses: "Gérer vos adresses e-mail",
	email_address: "adresse e-mail",
	email_addresses: "adresses e-mail",
	add_email_address: "Ajouter e-mail",
	primary: "Primaire",
	make_primary: "Rendre primaire",
	primary_email_address_taken_error: "Cette adresse e-mail est utilisée",
	daily_mail_frequency: "Je veux être alerté par e-mail lorsqu'une nouvelle sélection est effectuée.",
    daily_mail_add_awards: "Je veux aussi recevoir de nouvelles attributions",
	add_label: "Ajoutez un étiquette",
	add_label_group: "Ajouter groupe d'étiquettes",
	add_label_group_name: "Nom",
	add_label_group_color: "Couleur",
	rename_label: "Renommer",
	rename_label_popup_title: "Renommer",
	rename_label_popup_button: "Sauvegarder",
	move_label: "Déménager à groupe",
	move_label_popup_title: "Déménager étiquette",
	move_label_popup_button: "Déménager",
	remove_label_from_group: "Déconnecter de groupe",
	delete_label: "Effacer",
	delete_label_confirm: "Effacer étiquette ?",
	edit_label_group: "Ajuster",
	delete_label_group: "Effacer",
	delete_label_group_confirm: "Effacer groupe d'étiquettes ?",
	undo_label_changes: "annuler les modifications",
	label_must_have_a_name: "L'étiquette doit avoir un nom",
	label_group_must_have_a_name: "Le groupe d'étiquettes doit avoir un nom",
	label_with_name_already_exists_first_part: "Étiquette avec nom '",
	label_with_name_already_exists_second_part: "' existe déjà",
	label_group_with_name_already_exists_first_part: "Étiquette le groupe avec le nom '",
	label_group_with_name_already_exists_second_part: "' existe déjà",
	you_have_reached_your_label_limit: "Votre plan tarifaire actuel ne vous permet pas d'ajouter des étiquettes supplémentaires. Choisissez un plan tarifaire plus élevé ou contactez un représentant de TenderWolf.",
    invalid_label:"Etiquette invalide",
	masquerade: "Masquerade",
	logout: "Déconnexion",
	send_feedback: "Envoyer",
	sending_feedback: "Envoyer...",
	sent_feedback: "Feedback envoyé",
	invalid_email_address: "Adresse e-mail invalide",
	unknown_email_address: "Adresse e-mail inconnue",
	unsubscribe_warning: "En vous désinscrivant, vous ne recevrez plus de sélection quotidienne. Saisissez votre adresse e-mail ici pour compléter votre désinscription : ",
	unsubscribe_title: "Êtes-vous sûr de vouloir vous désinscrire ?",
	unsubscribe_successful_one : "Vous avez été désabonné de TenderWolf. S'il s'agit d'une erreur, veuillez mettre à jour vos paramètres ",
	unsubscribe_successful_two : "ici",
	unsubscribe_successful_three : " à nouveau.",
	feedback_your_input_is_invaluable: "Votre contribution est précieuse",
	feedback_we_are_constantly_evolving: "Tenderwolf est en constante évolution. L'équipe derrière TenderWolf est constamment à la recherche de moyens pour rendre TenderWolf encore plus rapide, plus précis et plus convivial, et pour ajouter des fonctions supplémentaires. Vos réactions sont précieuses à cet égard. N'hésitez donc pas à nous contacter si vous pensez que quelque chose pourrait être amélioré ou si une fonctionnalité spécifique vous manque.",
	feedback_fill_out_the_form: "Remplissez le formulaire ci-dessous et nous nous mettrons immédiatement au travail avec votre contribution.",
	feedback_thank_you: "Merci d'avance!",
	feedback_name_placeholder: "Nom",
	feedback_company_placeholder: "Entreprise",
	feedback_mail_placeholder: "Adresse e-mail",
	feedback_message_placeholder: "Message",
	search: "Recherche",
	unsavedChangesMessage: "Vous avez des changements non sauvés. Les changements seront perdus. Êtes-vous sûr de vouloir continuer ?",
	unsavedChangesTitle: "Changements non sauvés",
	createNewProfile: "Créez un nouveau profil",
	create: "Créez",
	deleteProfileMessage: "Souhaitez-vous supprimer ce profil ?",
	deleteProfileTitle: "Supprimer profil",
	editProfileTitle: "Ajuster profil",
	name_this_profile: "Nommez ce profil",
	select_or_create_a_profile: "Créez ou Sélectionnez un profil",
	create_a_profile: "Créez un profil",
	name_this_profile_for_example: "Nommez ce profil, par exemple: ",
    profile_name_placeholder: "Nom du profil",
	delete: "Supprimer",
	until: "À",
	current_plan: "Votre plan actuel",
	change_plan: "Changez votre plan",
	back: "Retour",
	change_plan_prompt: "Voulez-vous changer votre plan ?",
	click_here: "Cliquez ici",
	plan_information: "Information",
	plan_frequency: "Fréquence",
	plan_renewal_date: "Date de renouvellement",
	your_trial_plan_has_been_renewed: "Votre plan a été modifié avec succès.",
	your_trial_plan_has_not_been_renewed: "Vous avez dépassé le nombre maximum de périodes d'essai.",
	next_plan_name: "Plan",
	plan_frequency_monthly: "Mensuel",
	plan_frequency_yearly: "Annuel",
	choose: "Choisir",
	plan_change_message_part_one: "vous souhaitez passer au plan tarifaire ",
	plan_change_message_part_two_upgrade: "Après confirmation, cela se produit immédiatement",
	plan_change_message_part_two_downgrade: "Après confirmation, cela se produit le ",
    plan_change_message_downgrade_disclaimer : "Veuillez noter que votre nouveau plan comporte moins de fonctionnalités. Par conséquent, plusieurs de vos données peuvent être supprimées.",
	plan_change_success: "Votre plan a été modifié avec succès",
    future_plan_change_success : "Votre prochain plan a été modifié avec succès",
	no_invoice_address_title: "Numéro de TVA manquant",
	no_invoice_address_message: "Pour acheter un nouveau plan, nous avons besoin de votre numéro de TVA. Complétez vorte numéro de TVA dans l'onglet 'Facturation'.",
	help: "Help",
	navigateToCompanyPage: "Page d'entreprise"

}
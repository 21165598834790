import {S3FileDocument} from "../../types/tender";
import React, {useEffect, useState} from "react";
import {QueryParameters} from "../../pages/tenderDetails/main/TenderDetailsView";
import {usePostDownloadArchiveMutation} from "../../hooks/slices/tenderDetailsSlice";
import {Loader} from "../loader";

export interface DownloadFullProjectProps {
    setShowDownloadFullProjects: (b: boolean) => void;
    files: S3FileDocument[];
    archiveFileName: string;
}

export const DownloadFullProject: React.FC<DownloadFullProjectProps> = ({ setShowDownloadFullProjects, files,archiveFileName }) => {
    let queryParameters = QueryParameters();
    const [postDownloadArchive, { isLoading: downloadingFullProject }] = usePostDownloadArchiveMutation();
    const [downloadInitiated, setDownloadInitiated] = useState(false);
    useEffect(() => {
        if (queryParameters?.uuid && files && !downloadInitiated) {
            postDownloadArchive({ tenderUuid: queryParameters.uuid, files: files, archiveFileName: archiveFileName});
            setDownloadInitiated(true);
        }
    }, [queryParameters, files, downloadInitiated, postDownloadArchive]);
    useEffect(() => {
        if (!downloadingFullProject && downloadInitiated) {
            setShowDownloadFullProjects(false);
        }
    }, [downloadingFullProject, downloadInitiated]);

    if (downloadingFullProject) return <Loader/>;

    return <></>;
};
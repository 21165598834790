import { apiSlice } from "./apiSlice";
import { MultilingualField } from "../../types/tender";
import { CodeBranch } from "../../types/code";


/*
  REDUX STORE stuff
*/

/*
  API connection stuff
*/



export interface GetCompaniesByNameParams {
    name: string;
    source: CompanySuggestionsSource;
}

export interface GetCompaniesByVatNumberInParams {
    vatNumbers: string[];
    source: CompanySuggestionsSource;
}

export enum CompanySuggestionsSource {
    KBO = "KBO",
    COMPANY_PAGE_ANY = "COMPANY_PAGE_ANY",
    COMPANY_PAGE_BUYERS = "COMPANY_PAGE_BUYERS",
    COMPANY_PAGE_PARTICIPANTS = "COMPANY_PAGE_PARTICIPANTS"
}

export interface CompanySuggestionsDto{
    suggestions: CompanySuggestion[];
}

export interface CompanySuggestion {
    uuid: string;
    vatNumber: string;
    name: MultilingualField;
    address?: MultilingualField;
    startDate?: string;
}

export interface CompanyActivityDto{
    description: string,
    type: string
}

export interface CompanyDto{
    name: string,
    uuid: string,
    vatNumber: string,
    street: string,
    postalCode: string,
    city: string,
    country: string,
    website: string,
    phone: string,
    email: string,
    activities: CompanyActivityDto[]
}

export interface CompanyRelationDto{
    company: CompanyDto,
    relation: CompanyDto,
    sharedProjects: number
}

export interface CompanyCodeDto {
    cpvRoot: CodeBranch,
    count: number
}

export interface RecentTenderDto {
    title: MultilingualField,
    type: string,
    uuid: string
    publicationDate: string,
    totalHits: number
}

export interface CompanyPageDto {
    company: CompanyDto,
    competitors: CompanyRelationDto[],
    buyers: CompanyRelationDto[],
    suppliers: CompanyRelationDto[],
    tendersRecentlyWon: RecentTenderDto[],
    tendersRecentlyParticipatedIn: RecentTenderDto[],
    tendersContractedOpen: RecentTenderDto[],
    tendersContractedClosed: RecentTenderDto[],
    mainActivities: CompanyCodeDto[],
}

export interface GetCompanyPageParams {
    uuid: string;
}

export interface FindCompanyParams {
    kboNumber: string;
}

export interface TenderUuids {
    tenderUuids: string[]
}

export interface CompanyPageUuids {
    companyPageUuids: string[]
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getCompaniesByName: build.query<CompanySuggestionsDto, GetCompaniesByNameParams>({
            query: (params: GetCompaniesByNameParams) => {
                return {
                    url: `/shaggy/companies/suggest_names?prefix=` + params.name + `&source=` + params.source,
                };
            },
        }),
        getCompaniesByVatNumberIn: build.query<CompanySuggestionsDto, GetCompaniesByVatNumberInParams>({
            query: (params: GetCompaniesByVatNumberInParams) => {
                return {
                    url: `/shaggy/companies/find_by_vat_numbers_in?source=` + params.source + `&vat_numbers=` + params.vatNumbers.join(",")
                };
            },
        }),getCompanyPage: build.query<CompanyPageDto, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                return {
                    url: `/shaggy/companies/company_page?uuid=` + params.uuid,
                };
            },
        }), getCompanyUuid: build.query<string, string>({
            query: (params: string) => {
                return {
                    url: `/shaggy/companies/get_uuid_by_vat?vat=` + params,
                    responseHandler: "text",
                };
            },
        }), getCompanyForSignup: build.query<CompanyDto, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                return {
                    url: `/shaggy/signup/first-company/` + params.uuid,
                };
            },
        })
    }),
});

export const {useGetCompaniesByNameQuery, useGetCompanyPageQuery, useGetCompanyUuidQuery, useGetCompanyForSignupQuery, useGetCompaniesByVatNumberInQuery} = extendedApiSlice;

import { resetSearchToInitialState } from "../../../../hooks/slices/tenderSearchSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../app/hooks";
import { ResetIcon } from "../../../../components/icons";
import { FADE_IN } from "../../../../consts/FramerMotionVariants";
import { motion } from "framer-motion";

export const ResetAllFiltersButton: React.FC<{}> = () => {
  let { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetFilters = () => {
    dispatch(resetSearchToInitialState({ reset: true }));
  };
  return (
    <>
      <motion.span
        variants={FADE_IN}
        onClick={resetFilters}
        className={"reset-filters-icon"}
        title={t("searchFilterTags.RESET_FILTERS")}
      >
        <ResetIcon />
      </motion.span>
    </>
  );
};
import React, { useState } from "react";
import "./publicationTypes.scss";
import { useTranslation } from "react-i18next";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import { SearchFilter } from "../../../consts/SearchFilter";

export const PublicationTypesPage: React.FC<SavedSearchDetailsTabProps> = ({
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  userUuid,
  isLoading,
}) => {
  let { t } = useTranslation();
  const relevantPublicationTypes : SearchFilter[] = SearchFilterUtil.getPublicationTypeFiltersForSavedSearch();
  const [selectedPublicationTypes, setSelectedPublicationTypes] = useState<string[]>(
    SearchFilterUtil.translateContainsFieldsFiltersBack(savedSearch.containsFields)
  );
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const publicationTypeIsSelected = (publicationType: string) => {
    return selectedPublicationTypes.includes(publicationType);
  };
  const allRelevantPublicationTypesAreSelected = () => {
    //sort() both returns and sorts the original list otherwise ..
    return [...relevantPublicationTypes].sort().join(",") === [...selectedPublicationTypes].sort().join(",");
  };
  const persistCues = (): boolean => {
      updateSavedSearch({
        userUuid: userUuid,
        uuid: savedSearch.uuid,
        name: savedSearch.name,
        containsFields: SearchFilterUtil.translateContainsFieldsFilterStrings(selectedPublicationTypes),
      });
      //communicate changes to save button
      setUnsavedChanges(false);
      setDisableSaveButton(true);
      return true;
  };

  const selectAllPublicationTypes = () => {
    setSelectedPublicationTypes(relevantPublicationTypes);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const deselectAllPublicationTypes = () => {
    setSelectedPublicationTypes([]);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const toggleAllPublicationTypes = () => {
    if (allRelevantPublicationTypesAreSelected()) deselectAllPublicationTypes();
    else selectAllPublicationTypes();
  };
  const selectPublicationType = (languageIso: string) => {
    setSelectedPublicationTypes([...selectedPublicationTypes, languageIso]);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const deselectPublicationType = (languageIso: string) => {
    setSelectedPublicationTypes(selectedPublicationTypes.filter((l) => l !== languageIso));
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const togglePublicationType = (publicationType: string) => {
    if (publicationTypeIsSelected(publicationType)) deselectPublicationType(publicationType);
    else selectPublicationType(publicationType);
  };
  return (
    <>
      <div>
        <div className="subtitle">
          <label>{t("savedSearchSettings.formTypes")}</label>
        </div>
        <div className={"language-checkboxes"}>
          <div className={"checkbox-button-relative"}>
            <div key={"ALL"}>
              <input
                type="checkbox"
                id={"ALL"}
                name="checkBox"
                onChange={toggleAllPublicationTypes}
                checked={allRelevantPublicationTypesAreSelected()}
              />
              <label htmlFor={"ALL"}>{t("languages.ALL")}</label>
            </div>
            {relevantPublicationTypes.map((l) => (
              <>
                <div key={l.toUpperCase()}>
                  <input
                    type="checkbox"
                    id={l}
                    name="checkBox"
                    onChange={() => togglePublicationType(l)}
                    checked={publicationTypeIsSelected(l)}
                  />
                  <label htmlFor={l}>{t("searchFilters." + l)}</label>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className={"fill-button"}>
        <SaveButton saving={isLoading} save={persistCues} disabled={disableSaveButton} />
      </div>
    </>
  );
};


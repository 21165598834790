import React, { useState } from "react";
import "./popup.scss";
import { CloseIcon } from "../icons";
import { useTranslation } from "react-i18next";
import {FADE_IN} from "../../consts/FramerMotionVariants";
import {motion} from "framer-motion";

interface ConfirmPopupProps {
    title: string;
    message: string;
    confirmButtonMessage?: string;
    cancelButtonMessage?: string;
    confirm: () => void;
    close: () => void;
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = (props) => {
    let {t} = useTranslation();
    return <>
        <div className="popup-container">
            <motion.div className="popup-modal"  variants={FADE_IN} initial={"hidden"} animate={"visible"} exit={"hidden"}>
                <div className="popup-header-alignment">
                    <p>{props.title}</p>
                    <div onClick={props.close}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="popup-message">
                    <span>{props.message}</span>
                </div>
                <div className="popup-buttons">
                    <div className="popup-confirm-button">
                        <button onClick={props.confirm} data-testid={"confirm-popup-yes"}>{props.confirmButtonMessage !== undefined ? props.confirmButtonMessage : t("publications.yes")}</button>
                    </div>
                    <div className="popup-confirm-button">
                        <button onClick={props.close} data-testid={"confirm-popup-no"}>{props.cancelButtonMessage !== undefined ? props.cancelButtonMessage : t("publications.no")}</button>
                    </div>
                </div>

            </motion.div>
        </div>
    </>
}

interface ConfirmWithNamePopupProps {
    title: string;
    buttonMessage: string;
    defaultName?: string;
    confirm: (name: string) => void;
    close: () => void;
}

export const ConfirmWithNamePopup: React.FC<ConfirmWithNamePopupProps> = (props) => {
    const [name, setName] = useState<string>(props.defaultName ? props.defaultName : "");
    const [nameIsValid, setNameIsValid] = useState(name.trim() !== "");
    const changeName = (s: string) => {
        setName(s);
        setNameIsValid(s.trim() !== "");}
    const confirmWithName = () => {
        if (nameIsValid) props.confirm(name);
    }
    return <>
        <div className="popup-container">
            <div className="popup-modal">
                <div className="popup-header-alignment">
                    <p>{props.title}</p>
                    <div onClick={props.close}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={'input popup-input'}>
                    <input className={nameIsValid ? 'valid-input' : 'invalid-input'} type="text" value={name} onChange={(e) => changeName(e.currentTarget.value)}/>
                </div>
                <div className="popup-confirm-button">
                    <button onClick={confirmWithName}>{props.buttonMessage}</button>
                </div>
            </div>
        </div>
    </>
}
interface AlertPopupProps {
    title: string;
    message: string;
    close: () => void;
}
export const AlertPopup: React.FC<AlertPopupProps> = (props) => {
    let {t} = useTranslation();
    return <>
        <div className="popup-container">
            <div className="popup-modal">
                <div className="popup-header-alignment">
                    <p>{props.title}</p>
                    <div onClick={props.close}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="popup-message">
                    <span>{props.message}</span>
                </div>
                <div className="popup-buttons">
                    <div className="popup-confirm-button">
                        <button onClick={props.close}>{t("publications.ok")}</button>
                    </div>
                </div>

            </div>
        </div>
    </>
}
import React from "react";
import { CloseIcon } from "../../../../components/icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../app/hooks";
import {resetSortByTenderUuidFilters, SimilarTenderFilter} from "../../../../hooks/slices/tenderSearchSlice";
import {FADE_IN} from "../../../../consts/FramerMotionVariants";
import {motion} from "framer-motion";

export const SimilarTenderFilterButton: React.FC<{ similarTender: SimilarTenderFilter }> = ({ similarTender }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <>
      <motion.button data-cy={"similar-tender-uuid-filter-button"} variants={FADE_IN}>
        <span>{t("searchFilterTags.similarTenderUuid") + ": " + similarTender.tag}</span>
        <span onClick={() => dispatch(resetSortByTenderUuidFilters())} style={{ cursor: "pointer" }}>
          <CloseIcon />
        </span>
      </motion.button>
    </>
  );
};

import React from "react";
import "./setting-sidebar.scss";
import { SettingTab } from "../../consts/settingTabs";
import { SettingSidebarItem } from "./SettingSidebarItem";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../logout/Logout";


export interface SettingSidebarProps {
    activeTab: SettingTab,
    setTab: (tab: SettingTab) => void,
    userCanVisitLabelsPage: boolean;
    userCanMasquerade: boolean;
}


export const SettingSidebar: React.FC<SettingSidebarProps> = ({activeTab, setTab, userCanVisitLabelsPage, userCanMasquerade}) => {
    const {t} = useTranslation();
    const logout = () => {
        handleLogout();
    };
    return (
        <div>
            <div className="setting-sidebar-alignment">
                <div className="profile-title">
                    <h1>{t("settings.settings")}</h1>
                </div>
                <div className="child-menu-alignment">
                    <ul>
                        <SettingSidebarItem tab={SettingTab.ACCOUNT} isActive={SettingTab.ACCOUNT === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.COMPANY} isActive={SettingTab.COMPANY === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.INVOICE} isActive={SettingTab.INVOICE === activeTab} setTab={setTab}/>
                        {userCanVisitLabelsPage && <SettingSidebarItem tab={SettingTab.LABELS} isActive={SettingTab.LABELS === activeTab} setTab={setTab}/>}
                        <SettingSidebarItem tab={SettingTab.EMAIL} isActive={SettingTab.EMAIL === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.PROFILES} isActive={SettingTab.PROFILES === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.PASSWORD} isActive={SettingTab.PASSWORD === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.COUNTRIES} isActive={SettingTab.COUNTRIES === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.PLAN} isActive={SettingTab.PLAN === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.FEEDBACK} isActive={SettingTab.FEEDBACK === activeTab} setTab={setTab}/>
                        <SettingSidebarItem tab={SettingTab.HELP} isActive={SettingTab.HELP === activeTab} setTab={setTab}/>
                        {userCanMasquerade && <SettingSidebarItem tab={SettingTab.MASQUERADE} isActive={SettingTab.MASQUERADE === activeTab} setTab={setTab}/>}
                        <li onClick={logout}>{t("settings.logout")}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

import { ResourceKey } from "i18next";

export const Preferences: ResourceKey = {
	basicDetails: "Basisgegevens",
	firstName: "voornaam",
	emailAddress: "e-mailadres",
	language: "taal",
	username: "gebruikersnaam",
	password: "wachtwoord",
	addressDetails: "Adresgegevens",
	company: "bedrijf",
	street: "straat",
	number: "huisnummer",
	city: "stad",
	zip: "postcode",
	phone: "telefoonnummer",
	update: "instellingen bijwerken",
	unsubscribe_successful: "Je bent uitgeschreven",
	unsubscribe_error: "Ongekende gebruiker",
};

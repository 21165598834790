import React, { useEffect, useState } from "react";
import "./signup-curated-profiles.scss";
import {
  useGetSignupCuratedProfilesQuery,
  usePostSignupCuratedProfilesMutation,
} from "../../../hooks/slices/signupSlice";
import { useTranslation } from "react-i18next";
import {Loader} from "../../../components/loader";
import { ErrorPage } from "../../error";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../components/icons";
import { updateActivityStatus } from "../../../hooks/slices/userSlice";
import { useAppDispatch } from "../../../app/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CuratedUserProfile} from "../../../hooks/slices/savedSearchesSlice";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";
import {SavedSearchUtil} from "../../../utils/savedsearches";

export const SignupCuratedProfiles: React.FC = () => {
  useNavigateToOpportunitiesIfSignupAlreadyFinished();
  const {
    data: allProfiles,
    isLoading: getIsLoading,
    isSuccess: getIsSuccess,
    isError: getIsError,
    error: getError,
  } = useGetSignupCuratedProfilesQuery(); // skip query if params not defined

  if (getIsError || getError) return <ErrorPage error={getError}></ErrorPage>;
  if (getIsSuccess && allProfiles !== undefined && allProfiles.curatedUserProfiles !== undefined) {
    return <SignupCuratedProfilesWithData profiles={allProfiles.curatedUserProfiles} />;
  } else if (getIsLoading) return <Loader></Loader>;
  else return <></>;
};

export const SignupCuratedProfilesWithData: React.FC<{ profiles: CuratedUserProfile[] }> = ({ profiles }) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [
    postCuratedProfiles,
    { data: postResponse, isLoading: postIsLoading, isSuccess: postIsSuccess, isError: postIsError, error: postError },
  ] = usePostSignupCuratedProfilesMutation();
  const [selectedProfiles, setSelectedProfiles] = useState<CuratedUserProfile[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  let { t } = useTranslation();
  const [profilePages, ] = useState<ProfileCardRows[]>(getProfilePages(profiles, 9));
  const [profilePage, setProfilePage] = useState(0);
  const [userHasSeenAllProfiles, setUserHasSeenAllProfiles] = useState(false);
  let width = window.innerWidth;
  let  isMobile = width < 770; //max-sm in css
  let showNextButton = selectedProfiles.length > 0;
  const toggleProfile = (profile: CuratedUserProfile, select: boolean) => {
    if (select) {
      let newProfiles = [...selectedProfiles, profile];
      if (newProfiles.length > 5) newProfiles.shift(); //this limits the profiles to 5
      setSelectedProfiles(newProfiles);
    }
    else setSelectedProfiles(selectedProfiles.filter((p) => p.id !== profile.id));
  };
  const profileIsSelected = (profile: CuratedUserProfile): boolean => {
    return selectedProfiles.some((p) => p.id === profile.id);
  };
  const changePage = (page: number) => {
    setProfilePage(page);
    if (page === profilePages.length-1) setUserHasSeenAllProfiles(true);
  }
  const submit = () => {
    setShowLoader(true);
    postCuratedProfiles({curatedUserProfiles: selectedProfiles})
  }
  const navigateOrShowPopup = useSignupErrorHandler();
  useEffect(() => {
    if (postIsSuccess) {
      setShowLoader(false);
      dispatch(updateActivityStatus({activityStatus: "ACTIVE"}));
      if (searchParams.get("tenderUuid") != null) navigate("/tender/" + searchParams.get("tenderUuid"));
      else navigate("/opportunities?publication_date=last_week");
    } else if (postIsError && postError) {
      setShowLoader(false);
      navigateOrShowPopup(postError);
    }
  }, [postIsSuccess, postIsError, postError]);
  if (postIsLoading) return <Loader/>;
  else if(isMobile) return <ProfilesMobileView cards={profiles} toggleProfile={toggleProfile} profileIsSelected={profileIsSelected}/>
  else
    return (
      <div>
        {showLoader && <Loader />}
        <div className="layout-main-banner">
          <div className="container">
            <div className="intake-container">
              <h1 style={{height: "5vh"}}>{t("intake.curated_profiles_title")}</h1>
              <div style={{height: "60vh"}}>
                <ProfilesGrid profilePage={profilePages[profilePage]}
                              toggleProfile={toggleProfile}
                              profileIsSelected={profileIsSelected}
                              changePage={changePage}
                              pageIndex={profilePage}
                              totalPages={profilePages.length}
                />
              </div>
              <div style={{height: "5vh"}} className="button-alignment-footer">
                <div className={showNextButton ? "fill-button" : "fill-button disabled"}>
                  <button onClick={() => {submit()}} data-testid={"curated-profiles-submit-button"}>{t("intake.next")}</button>
                </div>
                <div className={userHasSeenAllProfiles && !showNextButton ? "fill-button" : "fill-button invisible"}>
                  <button onClick={() => {submit()}} data-testid={"curated-profiles-submit-button-empty"}>{t("intake.i_cant_find_my_sector")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

interface ProfileCardProps {
  profile: CuratedUserProfile;
  size: number;
  toggleProfile: (profile: CuratedUserProfile, select: boolean) => void;
  profileIsSelected: (profile: CuratedUserProfile) => boolean;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ profile, size, toggleProfile, profileIsSelected }) => {
  let {  i18n } = useTranslation();
  let profileName = SavedSearchUtil.getCuratedProfileName(profile, i18n.language);
  const toggle = () => {
    if (profile.id !== undefined) {
      toggleProfile(profile, !profileIsSelected(profile));
    }
  };
  return (
    <div
      onClick={toggle}
      className={"profile-card" + (profileIsSelected(profile) ? " selected" : "")}
      style={{ width: (size+50) + "px", height: size + "px" }}
      key={profileName}
      data-cy={"curated-profile-card"}
    >
      {/*todo translations*/}
      <div>{profileName}</div>
    </div>
  );
};

interface ProfileGridProps {
  profilePage: ProfileCardRows;
  toggleProfile: (profile: CuratedUserProfile, select: boolean) => void;
  profileIsSelected: (profile: CuratedUserProfile) => boolean;
  changePage: (page: number) => void;
  pageIndex: number;
  totalPages: number;
}

const ProfilesGrid: React.FC<ProfileGridProps> = ({ profilePage, toggleProfile, profileIsSelected, changePage, pageIndex, totalPages }) => {
  return (
    <>
      <div className={"flex-row-center"}>
        <div className={"flex-column-center"}>
          <div className={pageIndex > 0 ? "page-arrow" : "page-arrow disabled"}
               onClick={() => changePage(pageIndex-1)} data-testid={"curated-profiles-previous-page"}>
            <ArrowLeftIcon/>
          </div>
        </div>

        <div>
          <ProfilesGridRow cards={profilePage.top} profileIsSelected={profileIsSelected} toggleProfile={toggleProfile} className={"top-row"}/>
          <ProfilesGridRow cards={profilePage.center} profileIsSelected={profileIsSelected} toggleProfile={toggleProfile} className={"center-row"}/>
          <ProfilesGridRow cards={profilePage.bottom} profileIsSelected={profileIsSelected} toggleProfile={toggleProfile} className={"bottom-row"}/>
        </div>

        <div className={"flex-column-center"}>
          <div className={pageIndex < totalPages-1 ? "page-arrow" : "page-arrow disabled"}
               onClick={() => changePage(pageIndex+1)} data-testid={"curated-profiles-next-page"}>
            <ArrowRightIcon/>
          </div>
        </div>

      </div>

    </>
  );
};

interface ProfileGridRowProps {
  className: string;
  cards: ProfileCardWithSize[];
  toggleProfile: (profile: CuratedUserProfile, select: boolean) => void;
  profileIsSelected: (profile: CuratedUserProfile) => boolean;
}
const ProfilesGridRow: React.FC<ProfileGridRowProps> = ({className, cards, toggleProfile, profileIsSelected }) => {
  return <div className={"profile-cards-row " + className}>
    {cards.map((profile, i) => (
        <span key={i}>
        <ProfileCard
            profile={profile.profile}
            size={profile.size}
            toggleProfile={toggleProfile}
            profileIsSelected={profileIsSelected}
        />
        </span>

    ))}
  </div>
}

interface ProfilesMobileViewProps {
  cards: CuratedUserProfile[];
  toggleProfile: (profile: CuratedUserProfile, select: boolean) => void;
  profileIsSelected: (profile: CuratedUserProfile) => boolean;
}

const ProfilesMobileView: React.FC<ProfilesMobileViewProps> = ({cards, toggleProfile, profileIsSelected }) => {
  return <div className={"profile-cards-column"}>
    {cards.map((profile, i) => (
        <span key={i}>
          <ProfileCard
              profile={profile}
              size={150}
              toggleProfile={toggleProfile}
              profileIsSelected={profileIsSelected}
          />
        </span>

    ))}
  </div>
}

interface ProfileCardWithSize {
  profile: CuratedUserProfile;
  size: number;
}

interface ProfileCardRows {
  top: ProfileCardWithSize[];
  center: ProfileCardWithSize[];
  bottom: ProfileCardWithSize[];
}

function getProfilePages(profiles: CuratedUserProfile[], pageSize: number): ProfileCardRows[] {
    let profilePages: ProfileCardRows[] = [];
    for (let i = 0; i < profiles.length; i += pageSize) {
        let profilesForPage: CuratedUserProfile[] = profiles.slice(i, i + pageSize);
        profilePages.push(getProfileCardRows(profilesForPage));
    }
  return profilePages;
}

function getProfileCardRows(profiles: CuratedUserProfile[]): ProfileCardRows {
  let profileCards: ProfileCardWithSize[] = getProfileCardProps(profiles);
  let gaussianDistribution = gaussianSort(profileCards);
  let slices = sliceArray(gaussianDistribution, 3);
  return {
    top: gaussianSort(slices[0]),
    center: gaussianSort(slices[1]),
    bottom: gaussianSort(slices[2]),
  };
}

function gaussianSort(profileCards: ProfileCardWithSize[]): ProfileCardWithSize[] {
  let bigToSmall = profileCards.sort((a, b) => (a.size < b.size ? 1 : 0));
  let gaussianDistribution = [];
  for (let i = 0; i < bigToSmall.length; i++) {
    if (i % 2 === 0) gaussianDistribution.push(bigToSmall[i]);
    else gaussianDistribution.unshift(bigToSmall[i]);
  }
  return gaussianDistribution;
}

function sliceArray(array: any[], number: number): any[][] {
  let slices: any[][] = [];
  let step = Math.floor(array.length / number);
  for (let i = 0; i < number; i++) {
    slices[i] = array.slice(i * step, (i + 1) * step);
  }
  if (array.length % number !== 0) {
    let lastSlice = array.slice(number * step, array.length);
    slices[number - 1] = slices[number - 1].concat(lastSlice);
  }
  return slices;
}

function getProfileCardProps(profiles: CuratedUserProfile[]): ProfileCardWithSize[] {
  let profileCards: ProfileCardWithSize[] = [];
  let randomSizes = [];
  for(let i = 0; i < profiles.length; i++){
    randomSizes.push(getRandomInt(100, 200));
  } let randomSizesFromBigToSmall = randomSizes.sort().reverse();
  let profilesFromBigToSmall = profiles.sort((a, b) => (a.dutchName.length < b.dutchName.length ? 1 : 0));
  for (let i = 0; i < profiles.length; i++) {
    profileCards.push({ profile: profilesFromBigToSmall[i], size: randomSizesFromBigToSmall[i] });
  }
  return profileCards;
}

function getRandomInt(min: number, max: number) {
  return Math.ceil(Math.random() * (max - min) + min);
}

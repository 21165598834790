import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusAltIcon } from "../../../components/icons";

export interface EmailCardProps {
  emailAddressIsValid: (email: string) => boolean;
  addNewAdditionalAddressee: (email: string) => void;
}

export const EmailInput: React.FC<EmailCardProps> = ({ emailAddressIsValid, addNewAdditionalAddressee }) => {
  const {t} = useTranslation();
  const [addedAdditionalAddressee, setAddedAdditionalAddressee] = useState("");
  const [newMailAddressIsValid, setNewEmailAddressIsValid] = useState<boolean | undefined>(undefined);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setNewEmailAddressIsValid(emailAddressIsValid(e.currentTarget.value));
    setAddedAdditionalAddressee(e.currentTarget.value);
  }; const add = (addressee: string) => {
    setAddedAdditionalAddressee("");
    setNewEmailAddressIsValid(false);
    addNewAdditionalAddressee(addressee);
  }

  return <div className="email-card">
    <input type="text"
           style={{border: "none", background: "transparent", width: '100%'}}
           onChange={onChange}
           placeholder={t("settings.add_email_address")}
           value={addedAdditionalAddressee}/>
    {
        newMailAddressIsValid && (
            <div className={'edit-buttons'}
                 onClick={() => add(addedAdditionalAddressee)}
                 title={t("settings.add_email_address")}
            ><PlusAltIcon/></div>
        )
    }
  </div>
};

import React, {useEffect, useState} from 'react'
import './account.scss';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {PutUserParams, updateUser, usePutUserMutation, UserState} from "../../../hooks/slices/userSlice";
import {useTranslation} from "react-i18next";
import {showCustomErrorPopup, showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {SaveButton} from "../../../components/saveButton/SaveButton";
import {RaiseUser, UserInStore} from "../../../hooks/raiseUser";
import {AccountSettingsFormSkeleton} from "../accountSettingsFormSkeleton";
import {motion, useAnimation} from "framer-motion"
import {resetSearch} from "../../../hooks/slices/tenderSearchSlice";
import {dispatch} from "jest-circus/build/state";
import {FramerMotionUtil} from "../../../utils/framermotion";

export const Account: React.FC = () => {

    const dispatch = useAppDispatch();
    const authState = useAppSelector((state) => state.login);
    const [putUser, {data, isLoading, isSuccess, isError, error}] = usePutUserMutation();
    const userInStore: UserInStore = RaiseUser();
    useEffect(() => {
        if(isError && error) dispatch(showErrorPopup(error));
    }, [isError, error]);
    const onSubmit = (firstName: string, lastName: string, email: string, locale: string) => {
        if (authState.userID) {
            let userId: string = authState.userID;
            let userPutParams: PutUserParams = {userId, firstName, lastName, email, locale};
            putUser(userPutParams);
            dispatch(resetSearch()); //when the language changes the filters change
        }
    };
    useEffect(() => {
        //this shows the regular save button again after some time
        if (data && isSuccess){
            dispatch(updateUser(data));
        }
    }, [data])
    if (userInStore.isLoading || userInStore.user === undefined) return <AccountSettingsFormSkeleton inputElements={3}/>;
    else return <AccountForm user={userInStore.user} onSubmit={onSubmit} changesMade={isLoading}/>;
};


interface AccountFormProps {

    user: UserState;
    onSubmit: (firstName: string, lastName: string, email: string, locale: string) => void;
    changesMade: boolean;
}

const AccountForm: React.FC<AccountFormProps> = ({user, onSubmit, changesMade}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const controls = useAnimation();

    const [firstName, setFirstName] = useState(user.firstName);
    const onFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.currentTarget.value);
    };

    const [lastName, setLastName] = useState(user.lastName);
    const onLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.currentTarget.value);
    };

    const [email, setEmail] = useState(user.email);
    const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.currentTarget.value);
    };

    const [locale, setLocale] = useState(user.locale?.split("-")[0] || "nl");
    const onLocaleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setLocale(e.currentTarget.value);
    };

    const validateAndSubmit = (): boolean => {
        if (firstName && lastName && email && locale){
            onSubmit(firstName, lastName, email, locale);
            return true;
        } else {
            FramerMotionUtil.shakeAnimation(controls);
            dispatch(showCustomErrorPopup(t("intake.fill_in_all_fields")));
            return false;
        }
    }

    return (
        <motion.div animate={controls}>
            <div className='account-section-alignment'>
                <div className='page-alignment'>
                    <div>
                        <h1>{t("settings.account")}</h1>
                    </div>
                    {/*<div>*/}
                    {/*    <img src={CloseIcon} alt="CloseIcon"/>*/}
                    {/*</div>*/}
                </div>

                    <div className='account-box-fix-width'>
                        <div className='tow-col-grid'>
                            <div className='input'>
                                <input type="text" maxLength={255} required placeholder={t("intake.first_name")} onChange={onFirstNameChange}
                                       value={firstName}/>
                            </div>
                            <div className='input'>
                                <input type="text" maxLength={255} required placeholder={t("intake.last_name")} onChange={onLastNameChange}
                                       value={lastName}/>
                            </div>
                        </div>
                        <div className='input'>
                            <input type="text" maxLength={255} required placeholder={t("intake.email")} onChange={onEmailChange} value={email}/>
                        </div>
                        <div className='input'>
                            <select id="locale" onChange={onLocaleChange} value={locale} style={{cursor: "pointer"}}>
                                <option value="nl">Nederlands</option>
                                <option value="fr">français</option>
                                <option value="en">English</option>
                            </select>
                        </div>
                        <SaveButton saving={changesMade} save={validateAndSubmit}/>
                    </div>
            </div>
        </motion.div>
    )
}

import {
  LabelTenderDto,
  useAssignLabelToTenderMutation,
  useUnassignLabelFromTenderMutation,
} from "../../../../hooks/slices/labelSlice";
import { Label, LabelCategory } from "../../../../types/label";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  addLabelToResponseLabelsIfNew,
  removeLabelFromResponseLabels,
} from "../../../../hooks/slices/tenderSearchSlice";
import {
    addLabelCategory,
    addLabelToDefaultCategoryIfNew,
    decrementLabelCount,
    incrementLabelCount, updateUserLabelCategories,
} from "../../../../hooks/slices/userSlice";
import { showCustomErrorPopup, showErrorPopup } from "../../../../hooks/slices/snaccSlice";
import { useTranslation } from "react-i18next";
import { DebounceDatalistInput, DropdownElement } from "../../../../components/debounceInput/DebounceTextInput";
import { RaiseCurrentPlan } from "../../../../hooks/raiseCurrentPlan";
import { FeatureUtil } from "../../../../utils/features";
import { FeatureName } from "../../../../consts/features";
import { LabelsUtil } from "../../../../utils/labels";
import { t } from "i18next";

interface LabelsProps {
  tenderUuid: string;
  getLabels: (tenderUuid: string) => LabelTenderDto[];
  getLabelCategories: () => LabelCategory[];
}

export const Labels: React.FC<LabelsProps> = ({tenderUuid, getLabels, getLabelCategories}) => {
    //the labels are put in the store by the GenericSearchPage
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    // const labelsInStore: LabelCategoryDto[] | undefined = useAppSelector((state) => state.labelCategories.labelCategories);
    const userUuid = useAppSelector((state) => state.user.uuid);
    const featuresInStore = RaiseCurrentPlan();
    const labelLimit = FeatureUtil.getFeatureLimit(featuresInStore.currentPlan?.features, FeatureName.LABELS);
    const userHasLabelsLeft = (): boolean => {return labelLimit === undefined || labelLimit === null || LabelsUtil.getAllLabels(getLabelCategories()).length < labelLimit}
    const [assignLabel, {
        data: assignedLabel,
        isSuccess: assignLabelSuccess,
        isLoading: assigning,
        isError: assignIsError,
        error: assignError
    }] = useAssignLabelToTenderMutation();
    const [unassignLabel, {
        data: unassignedLabel,
        isSuccess: unassignLabelSuccess,
        isLoading: unassigning,
        isError: unassignIsError,
        error: unassignError
    }] = useUnassignLabelFromTenderMutation();

    const [showLoader, setShowLoader] = useState(false);

    const getLabelsForTender = (): LabelTenderDto[] => {
        return getLabels(tenderUuid);
    }

    const updateLabelsInStore = (labelCategories: LabelCategory[]) => {
        dispatch(updateUserLabelCategories({labelCategories: labelCategories}));
    }

    const addLabelToTender = (labelDto: Label) => {
        if (userUuid) {
            let label = {label: labelDto, tenderUuid: tenderUuid};
            if (labelDto.id !== undefined) {
                //we can immediately update the store because the id is already present
                dispatch(addLabelToResponseLabelsIfNew(label));
                assignLabel({label: labelDto, tenderUuid: tenderUuid}); //api
            } else {
                if(userHasLabelsLeft()){
                    //we have to wait for a response from the api to get the id
                    assignLabel(label); //api
                    setShowLoader(true);
                } else {
                    dispatch(showCustomErrorPopup(t("settings.you_have_reached_your_label_limit")));
                }
            }
        }
    }

    const removeLabelFromTender = (labelDto: Label) => {
        if (userUuid) {
            dispatch(removeLabelFromResponseLabels({label: labelDto, tenderUuid: tenderUuid})); //update store
            unassignLabel({label: labelDto, tenderUuid: tenderUuid}); //api
            // setShowLoader(true);
        }
    }


    //STORE
    useEffect(() => {
        if (assignLabelSuccess && assignedLabel) {
            //in case of a new label category
            if (assignedLabel.newLabelCategory != null){
                dispatch(addLabelCategory(assignedLabel.newLabelCategory));
            }
            dispatch(addLabelToResponseLabelsIfNew({label: assignedLabel.label, tenderUuid: tenderUuid}));
            dispatch(addLabelToDefaultCategoryIfNew(assignedLabel.label));
            dispatch(incrementLabelCount(assignedLabel.label));
        } else if (assignIsError && assignError) dispatch(showErrorPopup(assignError));
        setShowLoader(false);
    }, [assignedLabel, assignLabelSuccess, assignIsError, assignError]);

    useEffect(() => {
        if (unassignLabelSuccess && unassignedLabel) {
            dispatch(decrementLabelCount(unassignedLabel));
        } else if (unassignIsError && unassignError) {
            //this should add the label back to the tenderlabels, or refresh
            dispatch(showErrorPopup(unassignError));
        }
        setShowLoader(false);
    }, [unassignedLabel, unassignLabelSuccess, unassignIsError, unassignError]);

    return <LabelsWithData getLabelsForTender={getLabelsForTender}
                           getLabelCategories={getLabelCategories}
                           removeLabelFromTender={removeLabelFromTender}
                           addLabelToTender={addLabelToTender} isLoading={showLoader}/>

}

interface LabelsWithDataProps {
    getLabelsForTender: () => LabelTenderDto[];
    getLabelCategories: () => LabelCategory[];
    removeLabelFromTender: (labelDto: Label) => void;
    addLabelToTender: (labelDto: Label) => void;
    isLoading: boolean;
}

function convertLabelsToDropdownElements(labels: Label[]): DropdownElement[]{
    return Array.from(new Set(labels.map(l => l.name).map(l => ({value: l, label: l}))));
}

const LabelsWithData: React.FC<LabelsWithDataProps> = ({
                                                           getLabelsForTender,
                                                           getLabelCategories,
                                                           removeLabelFromTender,
                                                           addLabelToTender,
                                                           isLoading
                                                       }) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [showLabelInput, setShowLabelInput] = useState(false);
    let allLabels: Label[] = [];
    for (const labelCategory of getLabelCategories()) {
        for (const l of labelCategory.labels) {
            allLabels.push(l)
        }
    }
    const [dropdownElements, setDropdownElements] = useState<DropdownElement[]>(convertLabelsToDropdownElements(allLabels));
    let labelsForTenderNames: string[] = getLabelsForTender().map(l => l.label.name);
    // let dropdownElements: DropdownElement[] = Array.from(new Set(allLabels.map(l => l.name).filter(n => !existingLabelNames.includes(n)).map(l => ({value: l, label: l}))));
    const searchLabels = (query: string) => {
        let lowerCaseQuery = query.toLowerCase();
        let relevantLabels = Array.from(new Set(allLabels
            .filter(l => !labelsForTenderNames.includes(l.name))
            .filter(l => l.name.toLowerCase().includes(lowerCaseQuery))));
        setDropdownElements(convertLabelsToDropdownElements(relevantLabels));
    }
    const removeLabel = (label: Label) => {
        removeLabelFromTender(label)
    }
    const blur = () => {
        setShowLabelInput(false);
        setDropdownElements(convertLabelsToDropdownElements(allLabels));
    };
    const addLabel = (labelName: string) => {
        if (!labelsForTenderNames.map(l => l.toLowerCase()).includes(labelName.toLowerCase())) {
            let labelsWithName: Label[] = allLabels.filter(l => l.name.toLowerCase() === labelName.toLowerCase());
            let labelId = labelsWithName.length > 0 ? labelsWithName[0].id : undefined;
            addLabelToTender({id: labelId, name: labelName});
            setShowLabelInput(false);
            // setNewLabelName("");
        }
    }
    let labelsForTender = getLabelsForTender();

    return <>
            <div className="label-button-alignment">
                {labelsForTender.map((label) =>
                    <button className={"animated-label"} key={label.label.name} style={getLabelStyle(label.label, getLabelColor(label.label, getLabelCategories()))}>
                        <div data-cy={"label-name-tag"}>{label.label.name}</div>
                        <div onClick={() => removeLabel(label.label)} className={'remove-button'} data-cy={"remove-label-button"}>x</div>
                    </button>)
                }
                <AddLabelInput isLoading={isLoading} showLabelInput={showLabelInput} setShowLabelInput={setShowLabelInput} showAddLabelPlaceholder={labelsForTender.length === 0}
                               searchLabels={searchLabels} addLabel={addLabel} dropdownElements={dropdownElements} blur={blur}/>
            </div>
    </>
}

interface AddLabelInputProps{
    isLoading: boolean;
    showLabelInput: boolean;
    showAddLabelPlaceholder: boolean;
    setShowLabelInput: (show: boolean) => void;
    // existingLabels: LabelTenderDto[];
    searchLabels: (query: string) => void;
    addLabel: (labelName: string) => void;
    dropdownElements: DropdownElement[];
    blur: () => void;

}

export const AddLabelInput: React.FC<AddLabelInputProps> = (props: AddLabelInputProps)=> {
    return <button className={'add-label-button'} key={'new'} data-cy={"add-label-button"}>
    {props.isLoading && <span>...</span>}
    {!props.isLoading && !props.showLabelInput && props.showAddLabelPlaceholder && <span onClick={() => props.setShowLabelInput(true)}>{t("publications.addLabel")}</span>}
    {!props.isLoading && !props.showLabelInput && !props.showAddLabelPlaceholder && <span onClick={() => props.setShowLabelInput(true)}>+</span>}
    {!props.isLoading && props.showLabelInput && <span style={{width:'100%'}}>
                        <DebounceDatalistInput search={props.searchLabels}
                                               select={props.addLabel} delay={300}
                                               placeHolder={t("publications.addLabel")}
                                               dataList={props.dropdownElements}
                                               blur={props.blur}
                                               autoFocus={true}
                        />
    </span>
    }
</button>

}

function getLabelStyle(labelDto: Label, color: string) {
    return {
        color: `#${color}`,
        border: `1px solid #${color}`
    }
}

function getLabelColor(labelDto: Label, categories: LabelCategory[]): string {
    for (const c of categories) {
        for (const l of c.labels) {
            if (l.id === labelDto.id) return c.color;
        }
    }
    return '51565F'; //grey
}
import {CompanySuggestion} from "../hooks/slices/companySlice";
import {MultilingualFieldUtil} from "./multilingualfield";

export namespace CompanyUtil {
    export function isKboNumber(nationalIdentifier: string | null | undefined): boolean {
        let enterprise = "[0-9]{4}\\.[0-9]{3}\\.[0-9]{3}";
        let establishment = "[0-9]{1}\\.[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}";
        return nationalIdentifier != null
            && (nationalIdentifier.match(enterprise) != null || nationalIdentifier.match(establishment) != null);
    }

    export function findKboVatNumber(vat: string | undefined): string | undefined  {
        if(vat == null) return undefined;
        if(vat.match("BE\\d{4}\\.\\d{3}\\.\\d{3}")) return vat;//vat is actually a KBO number
        if(vat.match("\\d{4}\\.\\d{3}\\.\\d{3}")) return 'BE' + vat;//vat is actually a KBO number
        return undefined;
    }

    export function sortCompanySuggestionsByName(companies: CompanySuggestion[]): CompanySuggestion[] {
        return [...companies].sort((a, b) => {
            const nameA = MultilingualFieldUtil.translate(a.name, "nl");
            const nameB = MultilingualFieldUtil.translate(b.name, "nl");

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }
}

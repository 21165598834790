import React, {useEffect, useState} from 'react'

import './email-page.scss';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    EmailFrequency,
    EmailSettings,
    updateEmailPage,
    usePutEmailPageMutation
} from "../../../hooks/slices/userEmailPageSlice";
import {useTranslation} from "react-i18next";
import {showCustomErrorPopup, showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {Form} from "../../../components/form/Form";
import {APIErrorStatus} from "../../../utils/error";
import {ErrorPage} from "../../error";
import {EmailUtil} from "../../../utils/email";
import {AccountSettingsFormSkeleton} from "../accountSettingsFormSkeleton";
import {RaiseCurrentPlan} from "../../../hooks/raiseCurrentPlan";
import {Feature, FeatureName} from "../../../consts/features";
import {FeatureUtil} from "../../../utils/features";
import {SaveButton} from "../../../components/saveButton/SaveButton";
import {EmailPageInStore, RaiseEmailPage} from "../../../hooks/raiseEmailAddresses";
import {updateUserEmail} from "../../../hooks/slices/userSlice";
import {EmailCard} from "./EmailCard";
import {EmailInput} from "./EmailInput";

export default function EmailPage() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const userUuid = useAppSelector((state) => state.user.uuid);
    let emailsInStore: EmailPageInStore = RaiseEmailPage();
    const [putEmailPage, {
        data: putEmailPageResponse,
        isSuccess: putIsSuccess,
        isLoading: putIsLoading,
        isError: putIsError,
        error: putError
    }] = usePutEmailPageMutation();
    useEffect(() => {
        if (putIsError && putError) {
            if (APIErrorStatus(putError) === 409) {
                dispatch(showCustomErrorPopup(t("settings.primary_email_address_taken_error")));
            } else dispatch(showErrorPopup(putError));
        }
    }, [putIsError, putError]);
    useEffect(() => {
        if (putIsSuccess && putEmailPageResponse) {
            dispatch(updateEmailPage(putEmailPageResponse));
            dispatch(updateUserEmail(putEmailPageResponse.email));
        }
    }, [putEmailPageResponse]);
    const currentPlan = RaiseCurrentPlan();
    if (currentPlan.error) return <ErrorPage error={currentPlan.error}/>;
    if (emailsInStore.error) return <ErrorPage error={emailsInStore.error}/>;
    else if (emailsInStore.emailPage != null
        && emailsInStore.emailPage.email != null
        && emailsInStore.emailPage.additionalAddressees != null
        && emailsInStore.emailPage.mailFrequency != null
        && emailsInStore.emailPage.mailIncludesAwards != null
        && userUuid
        && currentPlan.currentPlan?.features != null) {
        return <>
            <EmailPageForm emailSettings={{
                email: emailsInStore.emailPage.email,
                additionalAddressees: emailsInStore.emailPage.additionalAddressees,
                mailFrequency: emailsInStore.emailPage.mailFrequency,
                mailIncludesAwards: emailsInStore.emailPage.mailIncludesAwards
            }}
                           features={currentPlan.currentPlan?.features}
                           isLoading={putIsLoading}
                           putEmailPage={(settings: EmailSettings) => putEmailPage({uuid: userUuid, body: settings})}
            />
        </>
    } else return <AccountSettingsFormSkeleton inputElements={3}/>;

}

interface EmailPageProps {
    emailSettings: EmailSettings;
    features: Feature[];
    isLoading: boolean;
    putEmailPage: (emailSettings: EmailSettings) => void;
}

const EmailPageForm: React.FC<EmailPageProps> = ({emailSettings, features, putEmailPage, isLoading}) => {
    const {t} = useTranslation();
    const [localEmailSettings, setLocalEmailSettings] = useState<EmailSettings>(emailSettings);
    const [changesWereMade, setChangesWereMade] = useState(false);
    const onSubmit = () => {
        putEmailPage(localEmailSettings);
        setChangesWereMade(false);
        return true;
    };

    const addAdditionalAddressee = (additionalAddressee: string) => {
        if (localEmailSettings.additionalAddressees.length < 50){
            setLocalEmailSettings({
                email: localEmailSettings.email,
                mailFrequency: localEmailSettings.mailFrequency,
                mailIncludesAwards: localEmailSettings.mailIncludesAwards,
                additionalAddressees: [...localEmailSettings.additionalAddressees, additionalAddressee],
            });
            setChangesWereMade(true);
        }
    };
    const removeAdditionalAddressee = (additionalAddressee: string) => {
        const newList = localEmailSettings.additionalAddressees.filter((a) => a !== additionalAddressee);
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: localEmailSettings.mailIncludesAwards,
            additionalAddressees: newList,
        }); setChangesWereMade(true);
    };
    const emailAddressIsValid = (emailAddress: string): boolean => {
        return (
            EmailUtil.emailAddressIsValid(emailAddress) &&
            emailAddress.toLowerCase() !== localEmailSettings.email.toLowerCase() &&
            !localEmailSettings.additionalAddressees.map((a) => a.toLowerCase()).includes(emailAddress.toLowerCase())
        );
    };

    function makePrimary(additionalAddressee: string) {
        let newAdditionalAddressees = [
            ...localEmailSettings.additionalAddressees.filter((a) => a !== additionalAddressee),
            localEmailSettings.email,
        ];
        setLocalEmailSettings({
            email: additionalAddressee,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: localEmailSettings.mailIncludesAwards,
            additionalAddressees: newAdditionalAddressees,
        });
        setChangesWereMade(true);
    }

    const onEmailFrequencyChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: e.currentTarget.checked ? EmailFrequency.DAILY : EmailFrequency.NEVER,
            mailIncludesAwards: e.currentTarget.checked ? localEmailSettings.mailIncludesAwards : false,
            additionalAddressees: localEmailSettings.additionalAddressees,
        }); setChangesWereMade(true);
    };

    const onIncludeAwardsChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: e.currentTarget.checked,
            additionalAddressees: localEmailSettings.additionalAddressees,
        }); setChangesWereMade(true);
    };

    function emailFrequencyIsDaily(): boolean {
        return localEmailSettings.mailFrequency === EmailFrequency.DAILY;
    }

    return (
        <div>
            <Form>
                <div className="email-page-alignment">
                    <div className="page-alignment">
                        <div>
                            <h1>{t("settings.email")}</h1>
                        </div>
                    </div>
                    <div className="checkbox-text-alignment">
                        <div>
                            <div className="child-product">
                                <input
                                    type="checkbox"
                                    id="emailFrequency"
                                    onChange={onEmailFrequencyChange}
                                    checked={emailFrequencyIsDaily()}
                                />
                                <label htmlFor="emailFrequency">{t("settings.daily_mail_frequency")}</label>
                            </div>
                        </div>
                    </div>
                    {emailFrequencyIsDaily() && FeatureUtil.hasFeature(features, FeatureName.UPDATE_MAIL_AWARDS) && (
                        <div className="checkbox-text-alignment">
                            <div>
                                <div className="child-product">
                                    <input
                                        type="checkbox"
                                        id="emailIncludesAwards"
                                        onChange={onIncludeAwardsChange}
                                        checked={localEmailSettings.mailIncludesAwards}
                                    />
                                    <label htmlFor="emailIncludesAwards">{t("settings.daily_mail_add_awards")}</label>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="email-add-box">
                        <label>{t("settings.manage_email_addresses")}</label>
                        <EmailCard email={localEmailSettings.email} isPrimary={true}/>
                        {localEmailSettings.additionalAddressees.map((additionalAddressee) => {
                            return (
                                <EmailCard
                                    key={additionalAddressee}
                                    email={additionalAddressee}
                                    isPrimary={false}
                                    removeAdditionalAddressee={removeAdditionalAddressee}
                                    makePrimary={makePrimary}
                                />
                            );
                        })}
                            <EmailInput addNewAdditionalAddressee={a => addAdditionalAddressee(a)} emailAddressIsValid={emailAddressIsValid}/>
                        <div className={changesWereMade ? "fill-button" : "fill-button disabled"}>
                            <SaveButton saving={isLoading} save={onSubmit}/>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};



import React, { useState } from "react";
import "./unsubscribe.scss";
import { useTranslation } from "react-i18next";
import { QueryParameters } from "../tenderDetails/main/TenderDetailsView";
import { ErrorPage } from "../error";
import {resetEmailPage, usePostUnsubscribeMutation} from "../../hooks/slices/userEmailPageSlice";
import { Loader } from "../../components/loader";
import { SaveButton } from "../../components/saveButton/SaveButton";
import { ValidatedInput } from "../../components/validatedInput/validatedInput";
import { EmailUtil } from "../../utils/email";
import { showCustomErrorPopup } from "../../hooks/slices/snaccSlice";
import { useAppDispatch } from "../../app/hooks";

export const UnsubscribePage: React.FC = () => {
  const { t } = useTranslation();
  let queryParameters = QueryParameters();
  let [queryHasBeenPerformed, setQueryHasBeenPerformed] = useState(false);
  const dispatch = useAppDispatch();
  let userUuid: string | undefined = queryParameters.uuid;
  const [postUnsubscribe, { isLoading, isSuccess, isError, error }] = usePostUnsubscribeMutation();
  const [emailAddress, setEmailAddress] = useState("");
  const save = () => {
    if (userUuid == null) return false;
    else if (!EmailUtil.emailAddressIsValid(emailAddress)) {
      dispatch(showCustomErrorPopup(t("settings.invalid_email_address")));
      setQueryHasBeenPerformed(true);
      return false;
    } else {
      postUnsubscribe({ uuid: userUuid, emailAddress: emailAddress });
      setQueryHasBeenPerformed(true);
      dispatch(resetEmailPage());
      return true;
    }
  };
  if (userUuid == null) return <ErrorPage errorCode={404} />;
  if (isError && error) {
    dispatch(showCustomErrorPopup(t("settings.unknown_email_address")));
  }
  return (
    <div>
      <div className="layout-main-banner">
        <div className="container">
          <div className="layout-top-alignment">
            <div className="login-box">
              {isLoading && <Loader></Loader>}
              {!isSuccess && (
                <div>
                  <div className="page-title">
                    <h3>{t("settings.unsubscribe_title")}</h3>
                    <span className={"border-span"}>
                      <span></span>
                    </span>
                    <p>{t("settings.unsubscribe_warning")}
                    </p>
                    <ValidatedInput
                      isInvalid={!EmailUtil.emailAddressIsValid(emailAddress)}
                      placeholder={""}
                      onChange={(s) => setEmailAddress(s.target.value)}
                      value={emailAddress}
                      testId={"add-email-address"}
                      highlightIfInvalid={queryHasBeenPerformed}
                    />
                    <SaveButton
                      saving={isLoading}
                      save={save}
                    ></SaveButton>
                  </div>
                </div>
              )}
              {isSuccess && (
                <div className="page-title">
                  <h3>{t("preferences.unsubscribe_successful")}</h3>
                  <span className={"border-span"}>
                    <span></span>
                  </span>
                  <p>{t("settings.unsubscribe_successful_one")}
                    <a href="/account?tab=email">{t("settings.unsubscribe_successful_two")}</a>
                    {t("settings.unsubscribe_successful_three")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

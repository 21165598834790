import {SearchFilter} from "../../../../consts/SearchFilter";
import React from "react";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import {CloseIcon} from "../../../../components/icons";
import {SearchResultsConfiguration} from "../../GenericSearchPage";
import {motion} from "framer-motion";
import {FADE_IN} from "../../../../consts/FramerMotionVariants";

interface SearchFilterButtonProps {
    searchFilter: SearchFilter;
    removeSearchFilter: (searchFilter: SearchFilter) => void;
    canBeRemoved: boolean;
    searchResultsConfiguration: SearchResultsConfiguration;
}

export const SearchFilterButton: React.FC<SearchFilterButtonProps> = ({
                                                                          searchFilter,
                                                                          removeSearchFilter,
                                                                          canBeRemoved,
                                                                          searchResultsConfiguration
                                                                      }) => {
    let {t} = useTranslation();
    // if (searchResultsConfiguration.hiddenFilters?.includes(searchFilter)) return <></>
    let tag = translateSearchFilterToTag(searchFilter, t);
    if (tag === "") return <></>
    return <>
            <motion.button variants={FADE_IN}>
                <span>{tag}</span>
                {canBeRemoved && <span onClick={() => removeSearchFilter(searchFilter)}>
                    <CloseIcon/>
                </span>}
            </motion.button>
    </>
}

export const SearchFilterButtonSkeleton: React.FC = () => {
    return <>
        <button>
            <span>...</span>
        </button>
    </>
}

function translateSearchFilterToTag(searchFilter: SearchFilter, t: TFunction) {
    if (searchFilter.valueOf().startsWith("COUNTRY_")) return t("searchFilterTags.COUNTRY_PREFIX") + t("countries." + searchFilter.valueOf().replace("COUNTRY_", ""));
    else if (searchFilter.valueOf().startsWith("LANGUAGE_")) return t("searchFilterTags.LANGUAGE_PREFIX") + t("languages." + searchFilter.valueOf().replace("LANGUAGE_", ""));
    else return t("searchFilterTags." + searchFilter.valueOf());
}

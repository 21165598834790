import React from "react";
import {SettingTab} from "../../consts/settingTabs";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";

export interface SettingSidebarItemProps {
    tab: SettingTab,
    isActive: boolean,
    setTab: (tab: SettingTab) => void,
}


export const SettingSidebarItem: React.FC<SettingSidebarItemProps> = ({tab, isActive, setTab}) => {
    const {t} = useTranslation();
    return <motion.li initial={{color: 'var(--black-5)'}}
                      animate={isActive ? {backgroundColor: 'rgba(30, 195, 203, 0.1)', color: 'var(--primary-color)'} : {color: 'var(--black-5)'}}
                      whileHover={{scale: 1.0, backgroundColor: 'rgba(30, 195, 203, 0.1)', color: 'var(--primary-color)'}}
                      transition={{ scale: { type: 'spring', stiffness: 500 } }}
        onClick={() => setTab(tab)}
        data-testid={"setting-sidebar-item-"+tab}
    >
        {t("settings."+tab)}
    </motion.li>
}


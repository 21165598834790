import React from "react";
import {CloseIcon} from "../../../../components/icons";
import {motion} from "framer-motion";
import {FADE_IN} from "../../../../consts/FramerMotionVariants";

interface QueryFilterButtonProps {
    query: string;
    resetQuery: () => void;
}

export const QueryFilterButton: React.FC<QueryFilterButtonProps> = ({query, resetQuery}) => {
    if (query === "") return <></>
    return <>
        <motion.button variants={FADE_IN}>
            <span>{query}</span>
            <span onClick={resetQuery} style={{cursor:"pointer"}}><CloseIcon/></span>
        </motion.button>
    </>
}
import { useRef, useEffect, useState } from 'react';
import styles from "./Modal.module.scss";

export interface ModalProps extends React.HTMLAttributes<HTMLDialogElement> {
    children: React.ReactNode;
    isVisible: boolean; 
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    classNameDialog?: string; 
    classNameCloseButton?: string; 
    classNameContent?: string; 
    classNameOverlay?: string; 
    ariaModal?: boolean | "true" | "false" | undefined;
    role?: string;
}

export const useDialog = (
    open: boolean, 
    dialogRef: React.RefObject<HTMLDialogElement>,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    useEffect(() => {
        const dialog = dialogRef.current;

        if (dialog) {
            const handleCancel = (event: Event) => {
                event.preventDefault();
            };

            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    setOpen(false);
                }
            };

            const handleOverlayClick = (event: MouseEvent) => {
                if (event.target === dialog) {
                    setOpen(false);
                }
            };

            dialog.addEventListener('cancel', handleCancel);
            document.addEventListener('keydown', handleKeyDown);
            dialog.addEventListener('click', handleOverlayClick);

            if (open) {
                //@ts-ignore
                dialog.showModal();
            } else {
                //@ts-ignore
                dialog.close();
            }

            return () => {
                dialog.removeEventListener('cancel', handleCancel);
                document.removeEventListener('keydown', handleKeyDown);
                dialog.removeEventListener('click', handleOverlayClick);
            };
        }
    }, [open, dialogRef, setOpen]);
};

export const Modal: React.FC<ModalProps> = ({
    children,
    isVisible,
    setIsVisible,
    classNameCloseButton = '',
    classNameContent = '',
    classNameDialog = '',
    classNameOverlay = '',
    ariaModal = 'true',
    role = 'dialog',
    ...props
}) => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    useDialog(isVisible, dialogRef, setIsVisible);

    return (
        <>
        { isVisible && <div className={`${styles['overlay']} ${classNameOverlay}`}/> }
        <div className={`${classNameDialog}`}>
            <dialog 
                ref={dialogRef}  
                className={`${styles['dialog']}`}
                aria-modal={ariaModal}
                role={role}
                {...props}
            >
                <button 
                    className={`${styles['close-button']} ${classNameCloseButton}`}
                    onClick={() => setIsVisible(false)}
                >
                    ×
                </button>
                <div 
                    className={`${styles['modal-content']} ${classNameContent}`}
                >
                    {children}
                </div>
            </dialog>
        </div>
        </>
    );
};

import React, {useEffect, useState} from 'react'
import './company-data.scss';
import {useAppDispatch} from "../../../app/hooks";
import {updateClient, usePutClientMutation} from "../../../hooks/slices/clientSlice";
import {useTranslation} from "react-i18next";
import {Country} from "../../../consts/countries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {RaiseClient} from "../../../hooks/raiseClient";
import {SaveButton} from "../../../components/saveButton/SaveButton";
import {AccountSettingsFormSkeleton} from "../accountSettingsFormSkeleton";
import {Client} from "../../../types/client";

//todo invoice details is basically a copy of this

export interface ClientTabProps {
    tabName: string;
    websiteEdit?: boolean;
    companyNameEdit?: boolean;
    vatNumberEdit?: boolean;
    emailAddressEdit?: boolean;
    phoneNumberEdit?: boolean;
    addressEdit?: boolean;
    automaticallySendInvoicesEdit?: boolean;
    invoiceReferenceEdit?: boolean;
    invoicePaymentTermEdit?: boolean;
}

export const ClientTab: React.FC<ClientTabProps> = (props) => {
    const clientInStore = RaiseClient();
    if(clientInStore.client === undefined || clientInStore.client.uuid === undefined) {
        return <AccountSettingsFormSkeleton inputElements={7}/>;
    }
    else return <ClientTabWithData client={clientInStore.client} clientTabProps={props}/>
    // return <AccountSettingsFormSkeleton inputElements={3}/>;
};

interface ClientTabWithDataProps {
    client: Client;
    clientTabProps: ClientTabProps;
}

const ClientTabWithData: React.FC<ClientTabWithDataProps> = ({client, clientTabProps}) => {
    const dispatch = useAppDispatch();
    const [putClient, {data: putClientResponse, isLoading, isSuccess, isError, error}] = usePutClientMutation();
    const [localClient, setLocalClient] = useState(client);
    const onSubmit = (client: Client) => {
            putClient(client);
            dispatch(updateClient(client));
            setLocalClient(client);
    };
    return (
        <ClientForm client={localClient} clientTabProps={clientTabProps} onSubmit={onSubmit} changesMade={isLoading}/>
    );
}

interface ClientFormProps {
    client: Client
    clientTabProps: ClientTabProps;
    onSubmit: (client: Client) => void;
    changesMade: boolean;
}

const ClientForm: React.FC<ClientFormProps> = ({client, clientTabProps, onSubmit, changesMade}) => {
    const {t} = useTranslation();
    const [companyName, setCompanyName] = useState(client.companyName != null ? client.companyName : '');
    const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCompanyName(e.currentTarget.value);
    };
    const [vatNumber, setVatNumber] = useState(client.vatNumber != null ? client.vatNumber : '');
    const onVatNumberChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setVatNumber(e.currentTarget.value);
    };
    const [streetName, setStreetName] = useState(client.streetName != null ? client.streetName : '');
    const onStreetChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setStreetName(e.currentTarget.value);
    };
    const [streetNumber, setStreetNumber] = useState(client.streetNumber != null ? client.streetNumber : '');
    const onNumberChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setStreetNumber(e.currentTarget.value);
    };
    const [cityName, setCityName] = useState(client.cityName != null ? client.cityName : '');
    const onCityChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCityName(e.currentTarget.value);
    };
    const [zipCode, setZipCode] = useState(client.zipCode != null ? client.zipCode : '');
    const onZipChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setZipCode(e.currentTarget.value);
    };
    const [country, setCountry] = React.useState<string|undefined>(client.countryIso != null ? client.countryIso : '');
    interface CountryDropdownElement {
        value: string;
        label: string;
    }
    function getCountryDropdownElements() {
        let elements: CountryDropdownElement[] = [];
        for (const country of Object.values(Country)) {
            if(country === Country.EU || country === Country.GB) continue;
            elements.push(
                {value: country, label: t('countries.' + country)});
        }
        return elements;
    }
    function getCurrentCountryDropdownElement(country: string): CountryDropdownElement {
        return {value: country, label: t('countries.' + country)}
    }
    const [phone, setPhone] = useState(client.phoneNumber != null ? client.phoneNumber : '');
    const onPhoneChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhone(e.currentTarget.value);
    };
    const [website, setWebsite] = useState(client.website != null ? client.website : '');
    const onWebsiteChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setWebsite(e.currentTarget.value);
    };
    const [emailAddress, setEmailAddress] = useState(client.emailAddress != null ? client.emailAddress : '');
    const onEmailAddressChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmailAddress(e.currentTarget.value);
    };
    const [invoiceReference, setInvoiceReference] = useState(client.invoiceReference != null ? client.invoiceReference : '');
    const onInvoiceReferenceChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceReference(e.currentTarget.value);
    };
    const validateAndSubmit = (): boolean => {
            onSubmit(
                {
                    uuid : client.uuid,
                    website : website,
                    companyName : companyName,
                    vatNumber : vatNumber,
                    emailAddress : emailAddress,
                    phoneNumber : phone,
                    streetName : streetName,
                    streetNumber : streetNumber,
                    zipCode : zipCode,
                    cityName : cityName,
                    countryIso : country,
                    automaticallySendInvoices : client.automaticallySendInvoices,
                    invoiceReference : invoiceReference,
                    invoicePaymentTerm : client.invoicePaymentTerm,
                }
            ); return true
    }
    return (
        <div>
            <div className='company-data-section-alignment'>
                <div className='page-alignment'>
                    <div>
                        <h1>{clientTabProps.tabName}</h1>
                    </div>
                </div>
                    <div className='company-data-box-fix-width'>
                        {clientTabProps.companyNameEdit &&
                            <div className='input'>
                                <input type="text" maxLength={255} placeholder={t("intake.company_name")} onChange={onNameChange} data-testid={"company-name"}
                                   value={companyName}/>
                            </div>}
                        {clientTabProps.vatNumberEdit &&
                            <div className='input'>
                                <input type="text" maxLength={255} placeholder={t("intake.vat_number_default")} onChange={onVatNumberChange} data-testid={"company-vat-number"}
                                       value={vatNumber}/>
                            </div>
                        }
                        {clientTabProps.addressEdit &&
                            <div className='two-col-grid'>
                                <div className='input'>
                                    <input type="text" maxLength={255} placeholder={t("settings.street")} onChange={onStreetChange} data-testid={"company-street"}
                                           value={streetName}/>
                                </div>
                                <div className='input'>
                                    <input type="text" maxLength={255} placeholder={t("settings.number")} onChange={onNumberChange} data-testid={"company-street-number"}
                                           value={streetNumber}/>
                                </div>
                            </div>
                        }
                        {clientTabProps.addressEdit &&
                            <div className='two-col-grid'>
                                <div className='input'>
                                    <input type="text" maxLength={255} placeholder={t("settings.zip")} onChange={onZipChange} data-testid={"company-zip"}
                                           value={zipCode}/>
                                </div>
                                <div className='input'>
                                    <input type="text" maxLength={255} placeholder={t("settings.town")} onChange={onCityChange} data-testid={"company-city"}
                                           value={cityName}/>
                                </div>
                            </div>
                        }

                        {clientTabProps.addressEdit &&
                            <div className='input'>
                                <Autocomplete
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0 none",
                                        }, "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                                            padding: "0 16px",
                                        }, "& .MuiOutlinedInput-root": {
                                            padding: 0,
                                        }
                                    }}
                                    disablePortal
                                    autoHighlight
                                    id="combo-box-demo"
                                    options={getCountryDropdownElements()}
                                    renderInput={(params) => <TextField {...params} hiddenLabel
                                                                        placeholder={t("intake.country")}/>}
                                    onChange={(event: any, country: CountryDropdownElement | null) => {
                                        if (country != null) {
                                            setCountry(country.value)
                                        }
                                    }}
                                    value={country ? getCurrentCountryDropdownElement(country) : null}
                                />
                            </div>
                        }
                        {clientTabProps.phoneNumberEdit &&
                            <div className='input'>
                                <input type="text" maxLength={255} placeholder={t("settings.phone")} onChange={onPhoneChange}
                                       value={phone}/>
                            </div>
                        }
                        {clientTabProps.websiteEdit &&
                            <div className='input'>
                                <input type="text" maxLength={255} placeholder={t("intake.website")} onChange={onWebsiteChange}
                                       value={website}/>
                            </div>
                        }
                        {clientTabProps.emailAddressEdit &&
                            <div className='input'>
                                <input type="text" maxLength={255} placeholder={t("intake.email")} onChange={onEmailAddressChange}
                                       value={emailAddress}/>
                            </div>
                        }
                        {clientTabProps.invoiceReferenceEdit &&
                            <div className='input'>
                                <input type="text" maxLength={255} placeholder={t("intake.invoice_reference")} onChange={onInvoiceReferenceChange}
                                       value={invoiceReference}/>
                            </div>
                        }
                        <SaveButton saving={changesMade} save={validateAndSubmit}/>
                    </div>
            </div>
        </div>
    )
}

import React, { useState } from "react";
import "./saveButton.scss";
import { CheckWhiteIcon } from "../icons";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

interface SaveButtonProps {
  saving: boolean;
  save: () => boolean;
  customSaveMessage?: string;
  customSavingMessage?: string;
  customSavedMessage?: string;
  disabled?: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({saving, save, customSaveMessage, customSavingMessage, customSavedMessage, disabled}) => {
    const {t} = useTranslation();
    let saveMessage = customSaveMessage ? customSaveMessage : t("settings.save");
    let savingMessage = customSavingMessage ? customSavingMessage : t("settings.saving");
    let savedMessage = customSavedMessage ? customSavedMessage : t("settings.saved");
    let [saveSuccess, setSaveSuccess] = useState(false);
    const saveAndSetLastSave = () => {
        if (save()) setSaveSuccess(true);
    }
    if (!saving && saveSuccess){
        setTimeout(() => {
            setSaveSuccess(false);
        }, 2000);
    }
    return <>
        <div className={disabled ? 'save-button disabled' : 'save-button'}>
            {saving &&
                <button type="submit" data-testid={"save-button-saving"}>{savingMessage}</button>
            }
            {!saving && !saveSuccess &&
                <button onClick={saveAndSetLastSave} type="submit" data-testid={"save-button-init"}>{saveMessage}</button>
            }
            {!saving && saveSuccess &&
                <button type="submit" data-testid={"save-button-done-saving"}>{savedMessage}
                    <CheckWhiteIcon/>
                </button>
            }
        </div>

        </>
    }
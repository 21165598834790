import React, {useState} from 'react'
import './setting.scss';
import {SkeletonSection} from "../tenderDetails/main/TenderDetailsView";
import {SubjectCodePage} from "./subjectCodes";
import {RegionCodePage} from "./regionCodes";
import {TFunction} from "i18next";
import {useTranslation} from "react-i18next";
import {GeneralSettingsPage} from "./generalSettings";
import {KeyWordsPage} from "./keywords";
import {ConfirmPopup} from "../../components/popup/Popup";
import {AccreditationPage} from "./accreditations";
import {TenderSearch} from "../../hooks/slices/tenderSearchSlice";
import { PublicationTypesPage } from "./publicationTypes";
import {motion, AnimatePresence} from "framer-motion";
import {FADE_IN_BOTTOM} from "../../consts/FramerMotionVariants";
import {ContractingAuthoritiesPage} from "./contractingAuthorities";

interface SavedSearchDetailsProps{
    userUuid: string;
    savedSearch: TenderSearch;
    updateSavedSearch: (savedSearch: TenderSearch) => void;
    isLoading: boolean;
}

enum Tab {
    GENERAL_SETTINGS = "GENERAL_SETTINGS",
    KEYWORDS= "KEYWORDS",
    CPV="CPV",
    NUTS="NUTS",
    ACCREDITATIONS="ACCREDITATIONS",
    PUBLICATION_TYPES="PUBLICATION_TYPES",
    CONTRACTING_AUTHORITIES="CONTRACTING_AUTHORITIES"
}

function getTabTranslation(tab: Tab, t: TFunction){
    switch (tab){
        case Tab.GENERAL_SETTINGS: return t("savedSearchSettings.generalSettings");
        case Tab.KEYWORDS: return t("savedSearchSettings.keyWords");
        case Tab.CPV: return t("savedSearchSettings.subjectCodes");
        case Tab.NUTS: return t("savedSearchSettings.regionCodes");
        case Tab.ACCREDITATIONS: return t("savedSearchSettings.accreditations");
        case Tab.PUBLICATION_TYPES: return t("savedSearchSettings.formTypes");
        case Tab.CONTRACTING_AUTHORITIES: return t("savedSearchSettings.contractingAuthorities");
    }
}

export const SavedSearchDetails: React.FC<SavedSearchDetailsProps> = ({userUuid, savedSearch, updateSavedSearch, isLoading}) => {

    let {t} = useTranslation();
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.GENERAL_SETTINGS);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [pendingTab, setPendingTab] = useState<Tab | undefined>(undefined);
    const changeTab = (tab: Tab) => {
        if (unsavedChanges) setPendingTab(tab);
        else setSelectedTab(tab);
    }
    const changeTabAndIgnoreUnsavedChanges = (tab: Tab) => {
        setUnsavedChanges(false);
        setPendingTab(undefined);
        setSelectedTab(tab);
    }
    return <>
        {pendingTab !== undefined &&
            <ConfirmPopup message={t("settings.unsavedChangesMessage")} title={t("settings.unsavedChangesTitle")}
                          confirm={() => changeTabAndIgnoreUnsavedChanges(pendingTab)} close={() => setPendingTab(undefined)}/>
        }
        <motion.div variants={FADE_IN_BOTTOM} initial="hidden" animate="visible">
                <div className='setting-section-alignment' style={{height: 'auto'}}>
                    <div className='container'>
                        <div className='setting-box'>
                            <div className='setting-child-box' style={{height: 'auto'}}>
                                <div className='tab'>
                                    <ul>
                                        {Object.values(Tab).map((tab, i) =>
                                            <li key={i} onClick={() => changeTab(tab)}
                                                data-testid={"user-profile-details-tab-" + tab}><span
                                                className={selectedTab === tab ? "active-tab" : ''}>{getTabTranslation(tab, t)}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className='setting-all-content-show'>
                                    {selectedTab === Tab.GENERAL_SETTINGS && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <GeneralSettingsPage userUuid={userUuid} savedSearch={savedSearch}
                                                                     updateSavedSearch={updateSavedSearch}
                                                                     setUnsavedChanges={setUnsavedChanges}
                                                                     isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                    {selectedTab === Tab.KEYWORDS && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <KeyWordsPage userUuid={userUuid} savedSearch={savedSearch}
                                                              updateSavedSearch={updateSavedSearch}
                                                              setUnsavedChanges={setUnsavedChanges}
                                                              isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                    {selectedTab === Tab.CPV && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <SubjectCodePage userUuid={userUuid} savedSearch={savedSearch}
                                                                 updateSavedSearch={updateSavedSearch}
                                                                 setUnsavedChanges={setUnsavedChanges}
                                                                 isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                    {selectedTab === Tab.NUTS && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <RegionCodePage userUuid={userUuid} savedSearch={savedSearch}
                                                                updateSavedSearch={updateSavedSearch}
                                                                setUnsavedChanges={setUnsavedChanges}
                                                                isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                    {selectedTab === Tab.ACCREDITATIONS && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <AccreditationPage userUuid={userUuid} savedSearch={savedSearch}
                                                                   updateSavedSearch={updateSavedSearch}
                                                                   setUnsavedChanges={setUnsavedChanges}
                                                                   isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                    {selectedTab === Tab.PUBLICATION_TYPES && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <PublicationTypesPage userUuid={userUuid} savedSearch={savedSearch}
                                                                      updateSavedSearch={updateSavedSearch}
                                                                      setUnsavedChanges={setUnsavedChanges}
                                                                      isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                    {selectedTab === Tab.CONTRACTING_AUTHORITIES && (<>
                                        <AnimatePresence>
                                            <motion.div variants={FADE_IN_BOTTOM} initial={"hidden"} animate={"visible"}
                                                        exit={"hidden"}>
                                                <ContractingAuthoritiesPage userUuid={userUuid} savedSearch={savedSearch}
                                                                                  updateSavedSearch={updateSavedSearch}
                                                                                  setUnsavedChanges={setUnsavedChanges}
                                                                                  isLoading={isLoading}/>
                                            </motion.div>
                                        </AnimatePresence>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
    </>
}

export interface SavedSearchDetailsTabProps {
    userUuid: string;
    savedSearch: TenderSearch;
    updateSavedSearch: (savedSearch: TenderSearch) => void;
    setUnsavedChanges: (unsavedChanges: boolean) => void;
    isLoading: boolean;
}

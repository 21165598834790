import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { PublicationDateToSortBy } from "../../../../consts/publicationDateToSortBy";
import { FormDropdownElement, SortByDropdown } from "../../../../components/dropdown";
import { updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import { CloseIcon, SettingsIcon } from "../../../../components/icons";
import { SearchResultsHeaderProps } from "./SearchResultsHeader";
import { FeatureUtil } from "../../../../utils/features";
import {Feature, FeatureName} from "../../../../consts/features";
import { RaiseCurrentPlan } from "../../../../hooks/raiseCurrentPlan";
import { ExportButton } from "../../../../components/exportButton/ExportButton";
import { SortBy, SortByParser } from "../../../../consts/SortBy";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";
import {AnimatePresence, motion} from "framer-motion";
import {FADE_IN_RIGHT} from "../../../../consts/FramerMotionVariants";

export const SortResultsDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
  let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  let dateSort = { name: t("publications.date"), value: PublicationDateToSortBy.PUBLICATION_DATE_DESC.valueOf() };
  let relevanceSort = { name: t("publications.relevance"), value: "RELEVANCE" };
  let sortByDropdownElements: FormDropdownElement[] = [dateSort, relevanceSort];
  // if (props.tenderSearchInput.query !== "") sortByDropdownElements.push(relevanceSort); //maybe block relevance sort for empty queries?
  if (searchFilters.sortBy === SortBy.RELEVANCE) sortByDropdownElements = sortByDropdownElements.reverse();
  const changeSortBy = (sortBy: FormDropdownElement) => {
    dispatch(
      updateSearchFilters({ sortBy: SortByParser.fromString(sortBy.value), userInteractions: userInteractions })
    );
  };
  return (
    <>
      {/*the span with 'marginTop' is an admittedly clumsy way to align the dropdown*/}
      <span style={{ marginTop: "16px" }}>
        <SortByDropdown dropdownElements={sortByDropdownElements} setSelectedElement={changeSortBy} />
      </span>
    </>
  );
};

export const FilterResultsPanel: React.FC<SearchResultsHeaderProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
  let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const [showFilterResults, setShowFilterResults] = useState(false);
  const toggleShowFilterResults = () => {
    setShowFilterResults(!showFilterResults);
  };

  const isSelected = (option: SortBy) => searchFilters.sortBy === option;

  const changeSortBy = (sortBy: SortBy) => {
      dispatch(updateSearchFilters({ sortBy: sortBy, userInteractions: userInteractions }));
  };
  const changePageSize = (pageSize: number) => {
    dispatch(updateSearchFilters({ pageSize: pageSize, userInteractions: userInteractions }));
  };
  return (
    <>
      <div className={'filter-results-panel'} tabIndex={0}>

        {!showFilterResults &&
            <div className={'filter-results-icon'} onClick={toggleShowFilterResults}>
              <SettingsIcon/>
            </div>}
          {showFilterResults && (
              <motion.div variants={FADE_IN_RIGHT} initial={"hidden"} animate={"visible"} className={'filter-results-buttons'}>
            <div><p>{t("publications.resultsPerPage")}</p>
              <p style={searchFilters.pageSize === 10 ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changePageSize(10)}>10</p>
              <p>|</p>
              <p style={searchFilters.pageSize === 20 ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changePageSize(20)}>20</p>
              <p>|</p>
              <p style={searchFilters.pageSize === 50 ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changePageSize(50)}>50</p>
              <p>|</p>
              <p style={searchFilters.pageSize === 100 ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changePageSize(100)}>100</p>
            </div>
            <div><p>{t("publications.sortResultsBy")}</p>
              <p style={isSelected(SortBy.DATE) ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changeSortBy(SortBy.DATE)}>{t("publications.date")}</p>
              <p>|</p>
              <p style={isSelected(SortBy.RELEVANCE) ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changeSortBy(SortBy.RELEVANCE)}>{t("publications.relevance")}</p>
              <p>|</p>
              <p style={isSelected(SortBy.DEADLINE) ? {fontWeight: 'bold'} : {cursor:'pointer'}} onClick={() => changeSortBy(SortBy.DEADLINE)}>{t("publications.deadline")}</p>
            </div>
                {props.searchConfiguration.info.showExportButton && FeatureUtil.hasFeature(features, FeatureName.EXPORT_EXCEL) &&
                    <ExportButton tenderUuids={props.tenderSearchOutput.tenders.map(t => t.tender.uuid)}
                                  tenderSearchInput={props.tenderSearchInput}/>
                }
              </motion.div>
          )}
      </div>
      {showFilterResults &&
          <div style={{width: '10px', marginRight: '-10px', cursor: 'pointer'}} onClick={toggleShowFilterResults}>
            <CloseIcon/>
          </div>
      }
    </>
  );
};



import React from "react";
import { motion } from "framer-motion";
import { FADE_IN } from "../../../../consts/FramerMotionVariants";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { CheckIcon, CheckWhiteIcon, DotsWhiteIcon, MobileMenuIcon } from "../../../../components/icons";
import { useNavigate } from "react-router-dom";

interface QueryFilterButtonProps {
  name: string;
  isActive: boolean;
  remove: (name: string) => boolean;
  add: (name: string) => boolean;
}

export const SavedSearchFilterButton: React.FC<QueryFilterButtonProps> = ({ name, isActive, add, remove }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const toggle = () => {
    if (isActive) {
      remove(name);
    } else {
      add(name);
    }
  };
  if (name === "") return <></>;
  return (
    <>
      {/*{!showNameInput && (*/}
      <motion.button
        variants={FADE_IN}
        style={
          isActive
            ? {
                borderColor: "var(--black-5)",
                backgroundColor: "var(--black-5)",
                color: "var(--bg-white)",
              }
            : {
                borderColor: "var(--bg-light-grey)",
                backgroundColor: "var(--bg-light-grey)",
                color: "var(--bg-white)",
              }
        }
        whileHover={{ opacity: 0.5, transition: { duration: 0.2 } }}
        animate={{ opacity: 1 }}
      >
        <span
          onClick={toggle}
          style={{
            cursor: "pointer",
            paddingRight: 0,
            color: "var(--bg-white)",
          }}
        >
          {t("savedSearchSettings.SavedSearchFilterButtonTitle") + ": " + name}
        </span>
        <span
          title={t("savedSearchSettings.settings")}
          onClick={() => navigate("/account?profile=" + name)}
          style={{
            width: "6px",
            marginLeft: "6px",
            cursor: "pointer",
          }}
        >
          <DotsWhiteIcon />
        </span>
      </motion.button>
      {/*)}*/}

      {/*{showNameInput && (*/}
      {/*  <motion.button*/}
      {/*    style={{*/}
      {/*      borderColor: "var(--black-5)",*/}
      {/*      backgroundColor: "var(--black-5)",*/}
      {/*      color: "var(--bg-white)",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <span>*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        autoFocus={true}*/}
      {/*        style={{ backgroundColor: "transparent", border: "none", height: "auto", color: "var(--bg-white)" }}*/}
      {/*        defaultValue={name}*/}
      {/*        onChange={(e) => {changeName(e)}}*/}
      {/*      />*/}
      {/*    </span>*/}
      {/*    <span*/}
      {/*      onClick={commitNewName}*/}
      {/*      style={{*/}
      {/*        width: "6px",*/}
      {/*        marginLeft: "6px",*/}
      {/*        cursor: "pointer",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <CheckWhiteIcon />*/}
      {/*    </span>*/}
      {/*  </motion.button>*/}
      {/*)}*/}
    </>
  );
};

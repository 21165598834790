import { Modal } from "../../../components/modal";
import { ErrorPage } from "../../error";
import React from "react";
import { CuratedUserProfile, useGetCuratedProfilesQuery } from "../../../hooks/slices/savedSearchesSlice";
import { useTranslation } from "react-i18next";
import { SavedSearchUtil } from "../../../utils/savedsearches";
import {
  AccountIcon,
  ArchitectureIcon,
  ClothingIcon,
  ConstructionIcon,
  CreditCardIcon,
  EconomyIcon,
  FurnitureIcon,
  GardeningIcon,
  GuardIcon,
  PlusCircledIcon,
  RecyclingIcon,
  SoftwareIcon,
  SyringeIcon,
  TransportationIcon,
  VehicleIcon,
  WrenchIcon,
} from "../../../components/icons";
import { useAppDispatch } from "../../../app/hooks";
import { showCustomErrorPopup } from "../../../hooks/slices/snaccSlice";

export interface AddProfilePopupProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  currentCuratedProfiles: CuratedUserProfile[] | undefined;
  createNewSavedSearch: (name: string) => void;
  selectCuratedProfile: (profile: CuratedUserProfile) => void;
}

export const AddProfilePopup: React.FC<AddProfilePopupProps> = ({ show, setShow, currentCuratedProfiles,createNewSavedSearch, selectCuratedProfile}) => {
    let {  t, i18n } = useTranslation();
    let dispatch = useAppDispatch();
    const {
        data: allCuratedProfiles,
        isError: curatedProfilesCouldNotBeFetched,
        isLoading: curatedProfilesAreLoading,
        error: curatedProfileFetchError,
    } = useGetCuratedProfilesQuery(); // skip query if params not defined
    const [profileName, setProfileName] = React.useState("");
    const [borderRedInput, setBorderRedInput] = React.useState(false);
    let currentlySelectedCuratedProfileIds: number[] = currentCuratedProfiles ? currentCuratedProfiles.map((profile) => profile.id) : [];
    let curatedProfilesNotYetSelected: CuratedUserProfile[] = allCuratedProfiles
        ? allCuratedProfiles.curatedUserProfiles.filter((profile) => !currentlySelectedCuratedProfileIds.includes(profile.id))
        : [];
    const filteredProfiles = () => {
        return curatedProfilesNotYetSelected
                .filter((profile) => SavedSearchUtil.getCuratedProfileName(profile, i18n.language).toLowerCase().includes(profileName.toLowerCase()));
    }
    const confirmCreateNewSavedSearch = (name: string) => {
        if (name.trim() === "") {
            dispatch(showCustomErrorPopup(t("settings.name_this_profile")));
            setBorderRedInput(true);
        } else {createNewSavedSearch(name);}
    }
    if (curatedProfilesCouldNotBeFetched || curatedProfileFetchError) return <ErrorPage error={curatedProfileFetchError}></ErrorPage>;
    return (
      <Modal isVisible={show} setIsVisible={setShow}>
        <div className={"add-profile-popup"}>
          <div>
            <h1>{t("savedSearchSettings.addProfile")}</h1>
          </div>
          <div className={"generic-profile-form"}>
            <div className="input">
              <input
                list="curated-profiles"
                maxLength={255}
                type="text"
                placeholder={t("settings.select_or_create_a_profile")}
                onChange={(e) => setProfileName(e.currentTarget.value)}
                data-testid={"profile-name-input"}
                style={borderRedInput ? { border: "1px solid red" } : {}}
              />
            </div>
            <div className={"tiles"}>
              <div className={"custom-profile"} onClick={() => confirmCreateNewSavedSearch(profileName)}>
                <PlusCircledIcon />
                <p>{t("settings.create_a_profile")}</p>
              </div>
              {filteredProfiles().map((profile) => (
                <div key={profile.id} onClick={() => selectCuratedProfile(profile)}>
                  <CuratedProfileIcon curatedProfile={profile} />
                  <p>{SavedSearchUtil.getCuratedProfileName(profile, i18n.language)}</p>
                </div>
              ))}
              {curatedProfilesAreLoading && (
                <TilesSkeleton/>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
}

const TilesSkeleton: React.FC = () => {
    return <>
        <div className={'skeleton skeleton-wave-background'}></div>
        <div className={'skeleton skeleton-wave-background'}></div>
        <div className={'skeleton skeleton-wave-background'}></div>
        <div className={'skeleton skeleton-wave-background'}></div>
        <div className={'skeleton skeleton-wave-background'}></div>
        <div className={'skeleton skeleton-wave-background'}></div>
    </>
}

const CuratedProfileIcon: React.FC<{curatedProfile: CuratedUserProfile}> = ({ curatedProfile }) => {
    let name = curatedProfile.dutchName.toLowerCase();
    if (name.includes("arch")) return <ArchitectureIcon/>;
    if (name.includes("bewaking")) return <GuardIcon/>;
    if (name.includes("bouw")) return <ConstructionIcon/>;
    if (name.includes("kledij")) return <ClothingIcon/>;
    if (name.includes("meubilair")) return <FurnitureIcon/>;
    if (name.includes("it -")) return <SoftwareIcon/>;
    if (name.includes("voertuig")) return <VehicleIcon/>;
    if (name.includes("vervoer")) return <TransportationIcon/>;
    if (name.includes("medisch")) return <SyringeIcon/>;
    if (name.includes("afval")) return <RecyclingIcon/>;
    if (name.includes("payment")) return <CreditCardIcon/>;
    if (name.includes("tuin")) return <GardeningIcon/>;
    if (name.includes("ingenieur")) return <WrenchIcon/>;
    if (name.includes("economie")) return <EconomyIcon/>;
    return <AccountIcon/>;
}
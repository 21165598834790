import React, { useEffect, useState } from "react";

import "./profile-settings.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { TenderSearch } from "../../../hooks/slices/tenderSearchSlice";
import { showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { Feature } from "../../../consts/features";
import { ErrorPage } from "../../error";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { FeatureUtil } from "../../../utils/features";
import { Loader } from "../../../components/loader";
import { SavedSearchUtil } from "../../../utils/savedsearches";
import { AccountSettingsFormSkeleton } from "../accountSettingsFormSkeleton";
import { RaiseSavedSearches } from "../../../hooks/raiseSavedSearches";
import {
  addCuratedProfileToState,
  addTenderSearchToState,
  CuratedUserProfile,
  deleteTenderSearchFromState,
  TenderSearchesState,
  useDeleteSavedSearchMutation,
  usePostCuratedProfileMutation,
  usePostSavedSearchMutation,
} from "../../../hooks/slices/savedSearchesSlice";
import { Language } from "../../../consts/languages";
import { UserLanguage } from "../../../hooks/raiseUser";
import { SavedSearchCard } from "./SavedSearchCard";
import { AddProfilePopup } from "./AddProfilePopup";
import { useNavigate } from "react-router-dom";

export const SavedSearchesTab: React.FC = () => {
  const userUuid = useAppSelector((state) => state.user.uuid);
  const savedSearchesInStore = RaiseSavedSearches();
  if (savedSearchesInStore.isLoading) return <AccountSettingsFormSkeleton inputElements={3} />;
  else if (savedSearchesInStore.error) return <ErrorPage error={savedSearchesInStore.error} />;
  else if (savedSearchesInStore.savedSearches.tenderSearches && userUuid) {
    return <SavedSearchSettings userUuid={userUuid} savedSearchesState={savedSearchesInStore.savedSearches} />;
  } else return <AccountSettingsFormSkeleton inputElements={3} />;
};

export const SavedSearchSettings: React.FC<{ userUuid: string; savedSearchesState: TenderSearchesState }> = ({
  userUuid,
  savedSearchesState,
}) => {
  const { t, i18n } = useTranslation();
  const language: Language = UserLanguage(i18n.language);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [curatedSavedSearches, setCuratedSavedSearches] = useState<TenderSearch[]>(
    savedSearchesState.curatedTenderSearches
      ? SavedSearchUtil.createCuratedTenderSearchDummies(savedSearchesState.curatedTenderSearches, language)
      : []
  );
  const [savedSearches, setSavedSearches] = useState<TenderSearch[]>(
    savedSearchesState.tenderSearches ? savedSearchesState.tenderSearches : []
  );
  const [showLoader, setShowLoader] = useState(false);
  const [deleteSavedSearchFromBackend, { isError: minusIsError, error: minusError, isSuccess: deleteSuccess }] =
    useDeleteSavedSearchMutation();
  useEffect(() => {
    if (minusIsError && minusError) dispatch(showErrorPopup(minusError));
  }, [minusIsError, minusError]);
  //features are always fetched by the navbar
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const [showAddProfilePopup, setShowAddProfilePopup] = useState(false);
  const [postSavedSearchToBackend, { data: newSavedSearch }] = usePostSavedSearchMutation();
  const [postCuratedProfileToBackend, { data: newCuratedProfile }] = usePostCuratedProfileMutation();

  // todo show edit details
  const createNewSavedSearch = (name: string) => {
    setShowAddProfilePopup(false);
    setShowLoader(true);
    let countries = FeatureUtil.getCountriesFromPublisherFeatures(features ? features : []);
    if (countries.length > 0){
      let savedSearch: TenderSearch = {
        name: name,
        userUuid: userUuid,
        countries: countries,
      }; postSavedSearchToBackend(savedSearch);
    }
  }
  const selectCuratedProfile = (profile: CuratedUserProfile) => {
    setShowAddProfilePopup(false);
    setShowLoader(true);
    postCuratedProfileToBackend(profile);
  }

  const deleteSavedSearch = (savedSearch: TenderSearch) => {
    deleteSavedSearchFromBackend(savedSearch);
    dispatch(deleteTenderSearchFromState(savedSearch));
    let isCurated = savedSearch.curatedProfileId != null;
    if (isCurated){
      let newCuratedProfiles: TenderSearch[] = curatedSavedSearches.filter((s) => s.curatedProfileId !== savedSearch.curatedProfileId);
      setCuratedSavedSearches(newCuratedProfiles);
    } else setSavedSearches(savedSearches.filter(s => s.uuid !== savedSearch.uuid));
    setShowLoader(true);
  };

  useEffect(() => {
    if (deleteSuccess) {
      setShowLoader(false); //state has already been updated
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (newSavedSearch !== undefined) {
      dispatch(addTenderSearchToState(newSavedSearch));
      setSavedSearches([...savedSearches, newSavedSearch]);
      setShowLoader(false);
      navigate("/account?profile=" + newSavedSearch.name); //this opens the details
    }
  }, [newSavedSearch]);
  useEffect(() => {
    if (newCuratedProfile !== undefined){
      dispatch(addCuratedProfileToState(newCuratedProfile));
      let newCuratedSavedSearchDummies: TenderSearch[] = [];
      newCuratedSavedSearchDummies = newCuratedSavedSearchDummies.concat(curatedSavedSearches);
      newCuratedSavedSearchDummies = newCuratedSavedSearchDummies.concat(SavedSearchUtil.createCuratedTenderSearchDummies([newCuratedProfile], language));
      setCuratedSavedSearches(newCuratedSavedSearchDummies);
      setShowLoader(false);
    }
  }, [newCuratedProfile]);
  return (
    <div>
      {showLoader && <Loader />}
      <div className="profiles-section-alignment">
        <div className="page-alignment ">
          <div>
            <h1>{t("settings.profiles")}</h1>
          </div>
        </div>
        {curatedSavedSearches.map((p, i) => (
          <SavedSearchCard
            savedSearch={p}
            key={p.curatedProfileId}
            deleteSavedSearch={deleteSavedSearch}
            userUuid={userUuid}
            curatedProfile={true}
          />
        ))}
        {savedSearches.map((p, i) => (
          <SavedSearchCard
            savedSearch={p}
            key={p.uuid ? p.uuid : i}
            deleteSavedSearch={deleteSavedSearch}
            userUuid={userUuid}
            curatedProfile={false}
          />
        ))}
        <div className="fill-button">
          {FeatureUtil.userHasExtraSavedSearchAvailable(savedSearches, features) && (
            <button onClick={() => setShowAddProfilePopup(true)} data-testid={"add-user-profile-button"}>
              {t("savedSearchSettings.addProfile")}
            </button>
          )}
        </div>
        {showAddProfilePopup && (<AddProfilePopup show={showAddProfilePopup}
                                                  setShow={setShowAddProfilePopup}
                                                  currentCuratedProfiles={savedSearchesState.curatedTenderSearches}
                                                  createNewSavedSearch={createNewSavedSearch}
                                                    selectCuratedProfile={selectCuratedProfile}
        />)}
      </div>
    </div>
  );
};



import React, { useEffect, useState } from "react";
import "./accreditations.scss";
import { useTranslation } from "react-i18next";
import { CodeType, CodeWithClass } from "../../../types/code";
import { MinusIcon, PlusIcon } from "../../../components/icons";
import { Feature } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import moment from "moment";
import { DebounceTextInput } from "../../../components/debounceInput/DebounceTextInput";
import { CodeUtil } from "../../../utils/codes";
import { Accreditations } from "../../../consts/accreditations";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";

export const AccreditationPage: React.FC<SavedSearchDetailsTabProps> = ({
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  isLoading,
  userUuid,
}) => {
  const clientFeatures: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  if (clientFeatures === undefined) return <></>;
  else {
    return (
      <AccreditationPageWithData
        savedSearch={savedSearch}
        updateSavedSearch={updateSavedSearch}
        setUnsavedChanges={setUnsavedChanges}
        isLoading={isLoading}
        userUuid={userUuid}
      />
    );
  }
};

export const AccreditationPageWithData: React.FC<SavedSearchDetailsTabProps> = ({savedSearch, updateSavedSearch, setUnsavedChanges, userUuid, isLoading}) => {

    let {t} = useTranslation();
    const relevantAccreditations: string[] = Accreditations;
    const [selectedCodes, setSelectedCodes] = useState<CodeWithClass[]>(savedSearch.accreditations ? savedSearch.accreditations : []);
    const [accreditationsToShow, setAccreditationsToShow] = useState(CodeUtil.convertAccreditationStringsToCodeWithClass(relevantAccreditations));
    const [showAll, setShowAll] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const updateAccreditations = (): boolean => {
        if(savedSearch.uuid) {
            updateSavedSearch({userUuid: userUuid, name: savedSearch.name, uuid: savedSearch.uuid, accreditations: selectedCodes});
            setUnsavedChanges(false);
            setDisableSaveButton(true);
            return true;
        } else return false;
    }
    const selectCodes = (codes: CodeWithClass[]) => {
        setSelectedCodes(codes);
        setUnsavedChanges(true);
        setDisableSaveButton(false);
    }
    const searchAccreditations = (query: string) => {
        if(query === "") resetAccreditations();
        else {
            let codesFound: string[] = CodeUtil.searchCodesAndReturnWithAncestors(query, relevantAccreditations , CodeType.ACCREDITATION,10000, t);
            setAccreditationsToShow(CodeUtil.convertAccreditationStringsToCodeWithClass(codesFound));
        }
    };
    const resetAccreditations = () => {
        setShowAll(false);
        setAccreditationsToShow(CodeUtil.convertAccreditationStringsToCodeWithClass(relevantAccreditations));
    };

    useEffect(() => {
        if (accreditationsToShow.length < relevantAccreditations.length){
            setShowAll(true); //problem: showall re-renders all 9000+ Cpv components
        }
    }, [accreditationsToShow])

    return (
        <>
            <div>
                <div className="region-all-content-alignment">
                    <div className="radio-region-alignment">
                        <DebounceTextInput search={searchAccreditations} delay={300} placeHolder={t("settings.search")}/>
                        <AccreditationsTree accreditationsToShow={accreditationsToShow}
                                            selectedAccreditations={selectedCodes}
                                            setSelectedAccreditations={selectCodes}
                                            showAll={showAll}/>
                    </div>
                </div>
            </div>
            <div className={'fill-button'}>
                <SaveButton saving={isLoading} save={updateAccreditations} disabled={disableSaveButton}/>
            </div>
        </>
    );
}

interface AccreditationsTreeProps{
    // accreditations are encoded as 'code with level' objects with the shape code-budgetClass, level 0 or 1
    accreditationsToShow: CodeWithClass[],
    selectedAccreditations: CodeWithClass[],
    setSelectedAccreditations: (codes: CodeWithClass[]) => void;
    showAll: boolean,
}
export const AccreditationsTree: React.FC<AccreditationsTreeProps> = ({accreditationsToShow, selectedAccreditations, setSelectedAccreditations, showAll}) => {
    const [selectedCodes, setSelectedCodes] = useState<CodeWithClass[]>(selectedAccreditations);
    const updateAccreditations = (codes: CodeWithClass[]) => {
        setSelectedCodes(codes);
        setSelectedAccreditations(codes);
    }
    const distinctCategories = Array.from(new Set(accreditationsToShow.map(a => a.code)));
    let accreditationCategories: CodeWithClass[] = [];
    distinctCategories.forEach(c => accreditationCategories.push({code: c, budgetClass: 0, type: CodeType.ACCREDITATION}));
    accreditationCategories = accreditationCategories.sort((a, b) => a.code.localeCompare(b.code));
    const isSelected = (category: string, budgetClass: number): boolean => {
        return selectedCodes.map(c => c.code + "-" + c.budgetClass).includes(category + "-" + budgetClass)}
    const addCode = (category: string, budgetClass: number) => {
        updateAccreditations([...selectedCodes, {code: category, budgetClass: budgetClass, type: CodeType.ACCREDITATION}]);
    }
    const removeCode = (category: string, budgetClass: number) => {
        updateAccreditations([...selectedCodes].filter(c => !(c.code === category && c.budgetClass === budgetClass)));
    }
    const toggleCode = (category: string, budgetClass: number) => {
        if(isSelected(category, budgetClass)) removeCode(category, budgetClass);
        else addCode(category, budgetClass);
    }
    const addCategory = (category: string) => {
        let newCodes: CodeWithClass[] = [...selectedCodes].filter(c => c.code !== category);
        let counter = 1;
        while(counter <= 8){
            newCodes.push({code: category, budgetClass: counter, type: CodeType.ACCREDITATION})
            counter = counter+1;
        }
        updateAccreditations(newCodes);
    }
    const removeCategory = (category: string) => {
        updateAccreditations([...selectedCodes].filter(c => c.code !== category));
    }
    const toggleCategory = (category: string) => {
        if (categoryIsSelected(category)) removeCategory(category);
        else addCategory(category);
    }
    const categoryIsSelected = (category: string): boolean => {return selectedCodes.filter(c => c.code === category).length === 8;}
    const hasSelectedBudgetClass = (category: string): boolean => {return selectedCodes.filter(c => c.code === category).length > 0;}


    return (
        <div className="all-content-accreditation-list-alignment">
            {accreditationCategories.length === 0 && <div className='no-results-text'>No results</div>}
            {accreditationCategories.map((code, i) =>
                <AccreditationBranch
                    key={codeKey(code.code, showAll)}
                    category={code}
                    toggleCode={toggleCode}
                    isSelected={isSelected}
                    hasSelectedBudgetClass={hasSelectedBudgetClass}
                    toggleCategory={toggleCategory}
                    categoryIsSelected={categoryIsSelected}
                />)}
        </div>
    );
}

function codeKey(code: string, show: boolean){
    return code + "-" + show + "-" + moment().valueOf();
}

interface AccreditationBranchProps{
    category: CodeWithClass;
    toggleCategory: (category: string) => void;
    categoryIsSelected: (category: string) => boolean;
    toggleCode: (category: string, budgetClass: number) => void;
    isSelected: (category: string, budgetClass: number) => boolean;
    hasSelectedBudgetClass: (category: string) => boolean;
}

//budget classes are added as level -1 codes
export const AccreditationBranch: React.FC<AccreditationBranchProps> = ({category, toggleCode, isSelected, hasSelectedBudgetClass, toggleCategory, categoryIsSelected}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(hasSelectedBudgetClass(category.code));
    const budgetClasses: CodeWithClass[] = [
            {code: category.code, budgetClass: 1, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 2, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 3, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 4, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 5, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 6, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 7, type: CodeType.ACCREDITATION},
            {code: category.code, budgetClass: 8, type: CodeType.ACCREDITATION}]
    const [budgetClassesShown, setBudgetClassesShown] = useState<CodeWithClass[]>(isOpen ?  budgetClasses: []);
    const showBudgetClasses = () => {setBudgetClassesShown(budgetClasses); setIsOpen(true);}
    const hideBudgetClasses = () => {setBudgetClassesShown([]); setIsOpen(false);}
    const toggleBudgetClassesVisbility = () => {
        if(!isOpen) showBudgetClasses();
        else hideBudgetClasses();

    }
    // let level = CodeUtil.getLevel(category.code, CodeType.ACCREDITATION);
    const budgetClassIsSelected = (budgetClass: CodeWithClass) => {return isSelected(category.code, budgetClass.budgetClass)}
    return <>
        <div>
            <div className={"first-layout-alignment"}>
                {isOpen && <div className={'center-align'} onClick={toggleBudgetClassesVisbility} data-cy={"hide-children-button"}><MinusIcon/></div>}
                {!isOpen && <div className={'center-align'} onClick={toggleBudgetClassesVisbility} data-cy={"show-children-button"}><PlusIcon/></div>}
                <div>
                    <div className='child-product'>
                        <input onClick={() => toggleCategory(category.code)}
                               defaultChecked={categoryIsSelected(category.code)}
                               type="checkbox"
                               id={category.code} data-cy={"input-checkbox"}/>
                        <label htmlFor={category.code}><span>{CodeUtil.getCodeWithTranslation(category, t)}</span></label>
                    </div>
                </div>
            </div>
            {isOpen && budgetClasses.map((budgetClass) =>
                    <div className={"sec-content-alignment sec-content-alignment-left-1"} key={codeKey(category.code + "-" + budgetClass.budgetClass, isOpen)}>
                        <div className={'center-align'}><MinusIcon/></div>
                        <div>
                            <div className='child-product'>
                                <input onClick={() => toggleCode(category.code, budgetClass.budgetClass)}
                                       defaultChecked={budgetClassIsSelected(budgetClass)}
                                       type="checkbox"
                                       id={category.code + "-" + budgetClass.budgetClass}/>
                                <label htmlFor={category.code + "-" + budgetClass.budgetClass} ><span>{t("codes.accreditation_budget_class_" + budgetClass.budgetClass)}</span></label>
                            </div>
                        </div>
                    </div>
            )}
        </div>
    </>
}
import React, { useState } from "react";
import "./generalSettings.scss";
import { useTranslation } from "react-i18next";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { FeatureUtil } from "../../../utils/features";
import { FeatureName } from "../../../consts/features";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { FieldGroup } from "../../../consts/FieldGroup";
import { SavedSearchDetailsTabProps } from "../index";

export const GeneralSettingsPage: React.FC<SavedSearchDetailsTabProps> = ({
  userUuid,
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  isLoading,
}) => {
  const { t } = useTranslation();
  const features = RaiseCurrentPlan().currentPlan?.features;
  const [profileName, setProfileName] = useState(savedSearch.name ? savedSearch.name : "");
  const [fieldGroupsToSearch, setFieldGroupsToSearch] = useState(savedSearch.fieldGroups ? savedSearch.fieldGroups : []);
  const [isFrameworkAgreement, setIsFrameworkAgreement] = useState(savedSearch.isFrameworkAgreement ? savedSearch.isFrameworkAgreement : false);
  const [isShelteredWorkshop, setIsShelteredWorkshop] = useState(savedSearch.isShelteredWorkshop ? savedSearch.isShelteredWorkshop : false);
  const [isShelteredProgram, setIsShelteredProgram] = useState(savedSearch.isShelteredProgram ? savedSearch.isShelteredProgram : false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const userCanToggleSearchAddenda = FeatureUtil.hasFeature(features, FeatureName.USER_PROFILES_SEARCH_ADDENDA);
  const searchAddenda = fieldGroupsToSearch.includes(FieldGroup.ADDENDA);
  const changeProfileName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setProfileName(e.target.value);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const toggleSearchAddenda = () => {
    if (savedSearch.uuid && userCanToggleSearchAddenda) {
      if (searchAddenda) {
        setFieldGroupsToSearch(fieldGroupsToSearch.filter((f) => f !== FieldGroup.ADDENDA));
      } else {
        setFieldGroupsToSearch([...fieldGroupsToSearch, FieldGroup.ADDENDA]);
      }
      setUnsavedChanges(true);
      setDisableSaveButton(false);
    }
  };
  const updateGeneralSettings = (): boolean => {
    if (savedSearch.uuid) {
      updateSavedSearch({
        userUuid: userUuid,
        name: profileName,
        fieldGroups: fieldGroupsToSearch,
        isFrameworkAgreement: isFrameworkAgreement,
        isShelteredWorkshop: isShelteredWorkshop,
        isShelteredProgram: isShelteredProgram,
      });
      setUnsavedChanges(false);
      setDisableSaveButton(true);
        return true;
    } else return false;
  };

  return (
    <>
      <div>
        <div className="general-settings-section">
          <div className="profile-input">
            <label>{t("settings.profile_name_placeholder")}</label>
            <div className="input">
              <input
                maxLength={255}
                type="text"
                value={profileName}
                placeholder={t("settings.name_this_profile")}
                onChange={changeProfileName}
                data-testid={"profile-name-input"}
              />
            </div>
          </div>
          <div className="matching-rules">
            <h1>{t("savedSearchSettings.settings")}</h1>
            <ToggleSwitch name={t("publications.searchAddenda")} checked={searchAddenda} change={toggleSearchAddenda} />
            <ToggleSwitch name={t("searchFilterTags.IS_FRAMEWORK_AGREEMENT_YES")} checked={isFrameworkAgreement} change={() => setIsFrameworkAgreement(!isFrameworkAgreement)} />
            <ToggleSwitch name={t("searchFilterTags.IS_SHELTERED_WORKSHOP")} checked={isShelteredWorkshop} change={() => setIsShelteredWorkshop(!isShelteredWorkshop)} />
            <ToggleSwitch name={t("searchFilterTags.IS_SHELTERED_PROGRAM")} checked={isShelteredProgram} change={() => setIsShelteredProgram(!isShelteredProgram)} />
          </div>
        </div>
      </div>
      <div className={"fill-button"} style={{marginTop: '24px'}}>
        <SaveButton saving={isLoading} save={updateGeneralSettings} disabled={disableSaveButton}/>
      </div>
    </>
  );
};

//

const ToggleSwitch: React.FC<{name: string, checked: boolean, change: () => void}> = ({name, checked, change}) => {
  return (
      <div className="switch-text-alignment">
        <div>
          <label className="switch">
            <input
                id="checkboxinp"
                type="checkbox"
                checked={checked}
                onChange={change}
                data-testid={"search-addenda-checkbox"}
            />
            <div className="slider round"></div>
          </label>
        </div>
        <div>
          <span>{name}</span>
        </div>
      </div>
  );
}

import {CodeUtil, CpvUtil} from "./codes";
import {CodeType} from "../types/code";
import {TFunction} from "react-i18next";
import {CuratedTenderSearch, CuratedUserProfile, TenderSearchesState} from "../hooks/slices/savedSearchesSlice";
import {FieldGroup} from "../consts/FieldGroup";
import {Language} from "../consts/languages";
import {TenderSearch} from "../hooks/slices/tenderSearchSlice";
import {RaiseSavedSearches, SavedSearchesInStore} from "../hooks/raiseSavedSearches";
import {t} from "i18next";

export namespace SavedSearchUtil {
  export function hasProfileWhichSearchesAddenda(savedSearches: TenderSearch[] | undefined): boolean {
    if (savedSearches === undefined) return false;
    for (const p of savedSearches) {
      if (p.fieldGroups != null && p.fieldGroups.includes(FieldGroup.ADDENDA)) {
        return true;
      }
    }
    return false;
  }

  export function getCuesFragment(savedSearch: TenderSearch, size: number): string {
    let cues = getQueryAsCues(savedSearch.query);
    let cuesFragment = cues !== undefined ? cues : [];
    if (cuesFragment.length > 3) {
      cuesFragment = cuesFragment.slice(0, size);
      cuesFragment.push("...");
    }
    return cuesFragment.join(", ");
  }

  export function getSavedSearchNames(savedSearchesInStore: SavedSearchesInStore, t: TFunction, language: string){
    const profileNames: string[] | undefined = savedSearchesInStore.savedSearches?.tenderSearches?.map(s => getOrCreateSavedSearchName(s, t));
    const curatedProfileNames: string[] | undefined = RaiseSavedSearches().savedSearches?.curatedTenderSearches?.map(s => getCuratedProfileName(s, language));
    const allProfileNames: string[] = [];
    if (profileNames) allProfileNames.push(...profileNames);
    if (curatedProfileNames) allProfileNames.push(...curatedProfileNames);
    return allProfileNames;
  }

  export function getCuratedProfileName(profile: CuratedUserProfile, language: string){
      return language === "fr" ? profile.frenchName : language === "en" ? profile.englishName : profile.dutchName;
  }

  export function getOrCreateSavedSearchName(savedSearch: TenderSearch, t: TFunction){
      return savedSearch.name ? savedSearch.name : getSampleTenderSearchName(savedSearch, t);
  }

  export function getSampleTenderSearchName(savedSearch: TenderSearch, t: TFunction): string {
    let name = "";
    let cues = getQueryAsCues(savedSearch.query);
    if (savedSearch.subjectCodes && savedSearch.subjectCodes.length > 0) {
      let sampleCode: string = CpvUtil.getCpvFullCode(savedSearch.subjectCodes[0].code);
      name = name + " " + CodeUtil.getCodeTranslation(sampleCode, CodeType.CPV, t);
    }
    if (cues && cues.length > 0) {
      if (cues.length === 1) name = name + " " + cues[0];
      else name = name + " " + cues.slice(0, 2).join(" & ");
    }
    if (savedSearch.regionCodes && savedSearch.regionCodes.length > 0) {
      let sampleCode: string = savedSearch.regionCodes[0].code;
      name = name + " " + CodeUtil.getCodeTranslation(sampleCode, CodeType.NUTS, t);
    }
    if (savedSearch.accreditations && savedSearch.accreditations.length > 0) {
      let sampleCode: string = savedSearch.accreditations[0].code;
      name = name + " " + CodeUtil.getCodeTranslation(sampleCode, CodeType.ACCREDITATION, t);
    }
    return name.trim();
  }

  export function createCuratedTenderSearchDummies(
      curatedTenderSearches: CuratedTenderSearch[] | undefined,
      language: Language
  ): TenderSearch[] {
    if (curatedTenderSearches === undefined) return [];
    let simplified: TenderSearch[] = [];
    for (const curatedTenderSearch of curatedTenderSearches) {
      if (curatedTenderSearch.tenderSearches.length > 0) {
        let first: TenderSearch = curatedTenderSearch.tenderSearches[0];
        let copy: TenderSearch = {
          userUuid: first.userUuid,
          curatedProfileId: curatedTenderSearch.id,
          name:
              language === Language.NL
                  ? curatedTenderSearch.dutchName
                  : language === Language.FR
                      ? curatedTenderSearch.frenchName
                      : curatedTenderSearch.englishName,
        };
        simplified.push(copy);
      }
    }
    return simplified;
  }

  export function getAllCues(savedSearches: TenderSearchesState): string[] {
    let cues: string[] = getCues(getAllTenderSearches(savedSearches));
    return Array.from(new Set(cues)).sort();
  }

  export function getCues(savedSearches: TenderSearch[] | undefined): string[] {
    let cues: string[] = [];
    if (savedSearches === undefined) return cues;
    for (const search of savedSearches) {
      cues = cues.concat(getQueryAsCues(search.query));
    }
    return Array.from(new Set(cues)).sort();
  }

  //c.f. same method in tenderwolf TextQueryParser
  export function getCuesAsQuery(cues: string[]) {
    let queryParts = [];
    for (const cue of cues) {
      queryParts.push(getCueAsQuery(cue));
    }
    return queryParts.join(" OR "); //todo not adding 'OR' here seems to change the results drastically?
  }

  //c.f. same method in tenderwolf TextQueryParser
  export function getCueAsQuery(cue: string) {
    if (cue.includes(" AND ")) return "(" + cue + ")";
    //also includes AND NOT
    else if (cue.includes(" ") || cue.includes("-") || cue.includes(".")) return '"' + cue + '"';
    else return cue;
  }

  export function getQueryAsCues(query: string | undefined): string[]{
    if (query == null || query.length == 0) return [];
    return query.split(" OR ")
        .map(cue => cue.replaceAll(RegExp("[\"()]", "g"),""));
  }

  export function getAllTenderSearches(savedSearchesState: TenderSearchesState): TenderSearch[] {
    let savedSearches: TenderSearch[] = [];
    if (savedSearchesState.tenderSearches !== undefined) savedSearches = savedSearches.concat(savedSearchesState.tenderSearches);
    if (savedSearchesState.curatedTenderSearches) {
      for (const curatedSearch of savedSearchesState.curatedTenderSearches) {
        savedSearches = savedSearches.concat(curatedSearch.tenderSearches);
      }
    }
    return savedSearches;
  }

  export function getSelectedTenderSearches(savedSearchesState: TenderSearchesState, savedSearchNames: string[], t: TFunction, language: string): TenderSearch[] {
    let savedSearches: TenderSearch[] = [];
    if (savedSearchesState.tenderSearches !== undefined) {
      let filteredSavedSearches = savedSearchesState.tenderSearches.filter(
          (s) => savedSearchNames.includes(SavedSearchUtil.getOrCreateSavedSearchName(s,t)));
      savedSearches = savedSearches.concat(filteredSavedSearches);
    }
    if (savedSearchesState.curatedTenderSearches) {
      let filteredCuratedProfiles = savedSearchesState.curatedTenderSearches.filter(
          (c) => savedSearchNames.includes(SavedSearchUtil.getCuratedProfileName(c, language)));
      for (const curatedSearch of filteredCuratedProfiles) {
        savedSearches = savedSearches.concat(curatedSearch.tenderSearches);
      }
    }
    return savedSearches;
  }

  export function sortProfilesByName(profiles: TenderSearch[]): TenderSearch[]{
    let names = profiles.map(p => p.name).sort((a, b) => a.localeCompare(b));
    return names.map(name => profiles.find(p => p.name === name))
        .filter(p => p !== undefined)
        .map(p => p as TenderSearch);
  }

}
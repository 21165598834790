export enum SearchFilter {

    FIELD_GROUP_TITLE="FIELD_GROUP_TITLE",
    FIELD_GROUP_DESCRIPTION="FIELD_GROUP_DESCRIPTION",
    FIELD_GROUP_SPECIFICATIONS="FIELD_GROUP_SPECIFICATIONS",
    FIELD_GROUP_ADDENDA="FIELD_GROUP_ADDENDA",
    FIELD_GROUP_AWARD_COMPANY_NAMES="FIELD_GROUP_AWARD_COMPANY_NAMES",
    FIELD_GROUP_BID_COMPANY_NAMES="FIELD_GROUP_BID_COMPANY_NAMES",
    FIELD_GROUP_CONTRACTING_AUTHORITY="FIELD_GROUP_CONTRACTING_AUTHORITY",

    PUBLICATION_DATE_EVERYTHING="PUBLICATION_DATE_EVERYTHING",
    PUBLICATION_DATE_YESTERDAY="PUBLICATION_DATE_YESTERDAY",
    PUBLICATION_DATE_LAST_WEEK="PUBLICATION_DATE_LAST_WEEK",
    PUBLICATION_DATE_LAST_MONTH="PUBLICATION_DATE_LAST_MONTH",
    PUBLICATION_DATE_LAST_TWO_MONTHS="PUBLICATION_DATE_LAST_TWO_MONTHS",
    PUBLICATION_DATE_LAST_YEAR="PUBLICATION_DATE_LAST_YEAR",
    PUBLICATION_DATE_CUSTOM="PUBLICATION_DATE_CUSTOM",
    PUBLICATION_DATE_PREVIOUS_WEEK_ONLY="PUBLICATION_DATE_PREVIOUS_WEEK_ONLY",

    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING",
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY",
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK",
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH",
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR",
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM",
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_PREVIOUS_WEEK_ONLY="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_PREVIOUS_WEEK_ONLY",

    DEADLINE_EVERYTHING="DEADLINE_EVERYTHING",
    DEADLINE_NOT_YET_EXPIRED="DEADLINE_NOT_YET_EXPIRED",
    DEADLINE_IN_7_DAYS="DEADLINE_IN_7_DAYS",
    DEADLINE_IN_14_DAYS="DEADLINE_IN_14_DAYS",
    DEADLINE_IN_1_MONTH="DEADLINE_IN_1_MONTH",
    DEADLINE_IN_2_MONTHS="DEADLINE_IN_2_MONTHS",
    DEADLINE_CUSTOM="DEADLINE_CUSTOM",
    DEADLINE_THIS_YEAR="DEADLINE_THIS_YEAR", //only used by the shortlist categories
    DEADLINE_PREVIOUS_YEAR="DEADLINE_PREVIOUS_YEAR", //only used by the shortlist categories
    DEADLINE_TWO_YEARS_AGO="DEADLINE_TWO_YEARS_AGO", //only used by the shortlist categories
    DEADLINE_MORE_THAN_TWO_YEARS_AGO="DEADLINE_MORE_THAN_TWO_YEARS_AGO", //only used by the shortlist categories

    HAS_PRIOR_INFORMATION_NOTICE="HAS_PRIOR_INFORMATION_NOTICE",
    HAS_CONTRACT_NOTICE="HAS_CONTRACT_NOTICE",
    HAS_CONTRACT_AWARD_NOTICE="HAS_CONTRACT_AWARD_NOTICE",
    HAS_DESIGN_CONTEST="HAS_DESIGN_CONTEST",
    HAS_CORRIGENDA="HAS_CORRIGENDA",
    HAS_AWARD_MODIFICATIONS="HAS_AWARD_MODIFICATIONS",
    HAS_OPENING_REPORT="HAS_OPENING_REPORT",
    HAS_MUNICIPAL_DECISION="HAS_MUNICIPAL_DECISION",

    HAS_ADDENDA="HAS_ADDENDA",
    HAS_AWARDS="HAS_AWARDS",

    DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE="DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE",
    DOES_NOT_HAVE_CONTRACT_NOTICE="DOES_NOT_HAVE_CONTRACT_NOTICE",
    DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE="DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE",
    DOES_NOT_HAVE_DESIGN_CONTEST="DOES_NOT_HAVE_DESIGN_CONTEST",
    DOES_NOT_HAVE_CORRIGENDA="DOES_NOT_HAVE_CORRIGENDA",
    DOES_NOT_HAVE_AWARD_MODIFICATIONS="DOES_NOT_HAVE_AWARD_MODIFICATIONS",
    DOES_NOT_HAVE_OPENING_REPORT="DOES_NOT_HAVE_OPENING_REPORT",
    DOES_NOT_HAVE_ADDENDA="DOES_NOT_HAVE_ADDENDA",

    CONTRACT_TYPE_SERVICES="CONTRACT_TYPE_SERVICES",
    CONTRACT_TYPE_WORKS="CONTRACT_TYPE_WORKS",
    CONTRACT_TYPE_SUPPLIES="CONTRACT_TYPE_SUPPLIES",

    PUBLICATION_RADIUS_EUROPEAN_UNION="PUBLICATION_RADIUS_EUROPEAN_UNION",
    PUBLICATION_RADIUS_NATIONAL="PUBLICATION_RADIUS_NATIONAL",

    CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION="CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION",
    CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC="CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC",
    CONTRACTING_AUTHORITY_TYPE_RA="CONTRACTING_AUTHORITY_TYPE_RA",
    CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY="CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY",
    CONTRACTING_AUTHORITY_TYPE_CGA="CONTRACTING_AUTHORITY_TYPE_CGA",
    CONTRACTING_AUTHORITY_TYPE_DEF_CONT="CONTRACTING_AUTHORITY_TYPE_DEF_CONT",
    CONTRACTING_AUTHORITY_TYPE_LA="CONTRACTING_AUTHORITY_TYPE_LA",
    CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT="CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT",
    CONTRACTING_AUTHORITY_TYPE_UTILITIES="CONTRACTING_AUTHORITY_TYPE_UTILITIES",
    CONTRACTING_AUTHORITY_TYPE_OTHER="CONTRACTING_AUTHORITY_TYPE_OTHER",

    PROCEDURE_TYPE_OPEN="PROCEDURE_TYPE_OPEN",
    PROCEDURE_TYPE_RESTRICTED="PROCEDURE_TYPE_RESTRICTED",
    PROCEDURE_TYPE_COMPETITIVE_DIALOGUE="PROCEDURE_TYPE_COMPETITIVE_DIALOGUE",
    PROCEDURE_TYPE_INNOVATION_PARTNERSHIP="PROCEDURE_TYPE_INNOVATION_PARTNERSHIP",
    PROCEDURE_TYPE_NEGOTIATED="PROCEDURE_TYPE_NEGOTIATED",
    PROCEDURE_TYPE_OTHER="PROCEDURE_TYPE_OTHER",

    IS_FRAMEWORK_AGREEMENT_YES="IS_FRAMEWORK_AGREEMENT_YES",
    IS_FRAMEWORK_AGREEMENT_NO="IS_FRAMEWORK_AGREEMENT_NO",
    IS_SHELTERED_WORKSHOP="IS_SHELTERED_WORKSHOP",
    IS_SHELTERED_PROGRAM="IS_SHELTERED_PROGRAM",

    COUNTRY_AT="COUNTRY_AT",
    COUNTRY_BE="COUNTRY_BE",
    COUNTRY_BG="COUNTRY_BG",
    COUNTRY_CH="COUNTRY_CH",
    COUNTRY_CY="COUNTRY_CY",
    COUNTRY_CZ="COUNTRY_CZ",
    COUNTRY_DE="COUNTRY_DE",
    COUNTRY_DK="COUNTRY_DK",
    COUNTRY_EE="COUNTRY_EE",
    COUNTRY_ES="COUNTRY_ES",
    COUNTRY_FI="COUNTRY_FI",
    COUNTRY_FR="COUNTRY_FR",
    COUNTRY_GR="COUNTRY_GR",
    COUNTRY_HU="COUNTRY_HU",
    COUNTRY_IE="COUNTRY_IE",
    COUNTRY_IS="COUNTRY_IS",
    COUNTRY_IT="COUNTRY_IT",
    COUNTRY_HR="COUNTRY_HR",
    COUNTRY_LV="COUNTRY_LV",
    COUNTRY_LT="COUNTRY_LT",
    COUNTRY_LU="COUNTRY_LU",
    COUNTRY_MT="COUNTRY_MT",
    COUNTRY_NL="COUNTRY_NL",
    COUNTRY_NO="COUNTRY_NO",
    COUNTRY_PL="COUNTRY_PL",
    COUNTRY_PT="COUNTRY_PT",
    COUNTRY_RO="COUNTRY_RO",
    COUNTRY_SE="COUNTRY_SE",
    COUNTRY_SI="COUNTRY_SI",
    COUNTRY_SK="COUNTRY_SK",
    COUNTRY_UK="COUNTRY_UK",
    COUNTRY_OTHER="COUNTRY_OTHER",

    LANGUAGE_NL = "LANGUAGE_NL",
    LANGUAGE_FR = "LANGUAGE_FR",
    LANGUAGE_EN = "LANGUAGE_EN",
    LANGUAGE_DE = "LANGUAGE_DE",
    LANGUAGE_EL = "LANGUAGE_EL",
    LANGUAGE_ES = "LANGUAGE_ES",
    LANGUAGE_ET = "LANGUAGE_ET",
    LANGUAGE_FI = "LANGUAGE_FI",
    LANGUAGE_GA = "LANGUAGE_GA",
    LANGUAGE_HR = "LANGUAGE_HR",
    LANGUAGE_HU = "LANGUAGE_HU",
    LANGUAGE_IT = "LANGUAGE_IT",
    LANGUAGE_LT = "LANGUAGE_LT",
    LANGUAGE_LV = "LANGUAGE_LV",
    LANGUAGE_MT = "LANGUAGE_MT",
    LANGUAGE_PL = "LANGUAGE_PL",
    LANGUAGE_PT = "LANGUAGE_PT",
    LANGUAGE_RO = "LANGUAGE_RO",
    LANGUAGE_SK = "LANGUAGE_SK",
    LANGUAGE_SL = "LANGUAGE_SL",
    LANGUAGE_SV = "LANGUAGE_SV",
    LANGUAGE_BG = "LANGUAGE_BG",
    LANGUAGE_CS = "LANGUAGE_CS",
    LANGUAGE_DA = "LANGUAGE_DA"


}

export namespace SearchFilterParser {

    export function findAll(): SearchFilter[]{
        return Object.values(SearchFilter);
    }

    export function fromString(s: string): SearchFilter{
        return (SearchFilter as any)[s.toUpperCase()];
    }

}
